import {Component} from "react";
import classes from "./SectionIto6.module.scss";

class SectionIto6 extends Component {
    render() {
        return(
            <section className={classes.SectionIto6}>
                <div className={classes.wrapper}>
                    <div className={classes.box}>
                        <p className={classes.round}>Round 1</p>
                        <h5 className={classes.h51}>Private Sale</h5>
                        <p>Starts May 15</p>
                        <p>$3 per token</p>
                        <p>12 months lockup</p>
                        <button>Join</button>
                    </div>

                    <div className={classes.box}>
                        <p className={classes.round}>Round 2</p>
                        <h5>Pre-sale</h5>
                        <p>Starts June 1</p>
                        <p>$4 per token</p>
                        <p>6 month lockup</p>
                        <button className={classes.active}>not started yet</button>
                    </div>

                    <div className={classes.box}>
                        <p className={classes.round}>Round 3</p>
                        <h5>Public Sale</h5>
                        <p>Starts June 15</p>
                        <p>Dutch Auction</p>
                        <p>Freely trading</p>
                        <button className={classes.noActive}>not started yet</button>
                    </div>

                </div>
            </section>
        )
    }
}

export default SectionIto6