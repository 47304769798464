import React, {Component} from "react";
import classes from "./SectionMobileApp4.module.scss";
import y from './y.svg'
import x from './x.svg'

class SectionMobileApp4 extends Component{
    render() {
        return(
            <section className={classes.sectionMobileApp4}>
                <div className={classes.rowh}>
                    <h3>How Sizzle benefits
                        users <span className={classes.vs}>vs</span> others <span className={classes.red}>don’t</span></h3>
                </div>

                <div className={classes.row}>
                    <div className={classes.blockTitle}>

                        <div className={classes.rowText}>
                            <div className={classes.text}>

                            </div>
                            <div className={classes.icoSizzle}>
                                Sizzle
                            </div>
                            <div className={classes.icoOthers}>
                                Others
                            </div>
                        </div>

                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.block}>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                        <div className={classes.rowText}>
                            <div className={classes.text}>
                                Reward you for inviting friends
                            </div>
                            <div className={classes.icoSizzle}>
                                <img src={y}/>
                            </div>
                            <div className={classes.icoOthers}>
                                <img src={x}/>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}
export default SectionMobileApp4