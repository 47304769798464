import classes from './Button.module.scss'

function Button(props){
  return(
    <button className={classes.btn1} onClick={props.click} id={props.id}>
      {props.text}
    </button>
  )
}

export default Button