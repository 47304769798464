import classes from "./SectionHowEarn.module.scss"
import icon1 from "./icon1.svg"
import icon2 from "./icon2.svg"
import icon3 from "./icon3.svg"
import icon4 from "./icon4.svg"
import icon5 from "./icon5.svg"
import icon6 from "./icon6.svg"
import icon7 from "./icon7.svg"
import icon8 from "./icon8.svg"
import icon9 from "./icon9.svg"
import React, {Component} from "react";
import BG from './BGBlur.png'
import anime from "animejs/lib/anime.es.js"


const animateBG = () =>{
  let element = document.querySelector('.'+classes.BGCSS);


  anime({
    targets: element,
    easing: 'linear',
    background: {
      value: [
        'conic-gradient(from 77.22deg at 50.02% 49.99%, #C7D6F6 -171.75deg, #EDEDF4 -104.73deg, #E6CBF4 23.34deg, #CBF4F2 78.75deg, #F8FAFE 179.74deg, #C7D6F6 188.25deg, #EDEDF4 256.73deg)',
        'conic-gradient(from 317.22deg at 50.02% 49.99%, #C7D6F6 69.75deg, #EDEDF4 136.73deg, #E6CBF4 263.34deg, #CBF4F2 318.75deg, #F8FAFE 419.74deg, #C7D6F6 428.25deg, #EDEDF4 496.73deg)',
        'conic-gradient(from 197.22deg at 50.02% 49.99%, #C7D6F6 -51.75deg, #EDEDF4 16.73deg, #E6CBF4 143.34deg, #CBF4F2 198.75deg, #F8FAFE 299.74deg, #C7D6F6 308.25deg, #EDEDF4 376.73deg)']
    },
    duration: 30000,
    loop: true,

  })
}


class SectionHowEarn extends Component{

  componentDidMount() {
    // animateBG();
  }

  render() {
    return (
      <section className={classes.SectionHowEarn} id="SectionHowEarn">
        <div className={classes.BGCSS}>
          <img src={BG} alt=""/>
        </div>
        <div className="siteWidth">
          <h2 className={classes.title}>
            How to earn tokens on Sizzle
          </h2>
          <div className={classes.innerWrapper}>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon1} alt=""/>
              </div>
              <p>Monetize 100% of your<br/> views on Sizzle</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon7} alt=""/>
              </div>
              <p>Sell your Videos as NFT<br/> on Sizzle NFT Marketplace</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon3} alt=""/>
              </div>
              <p>Invite your first and second level<br/> audience to join Sizzle</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon4} alt=""/>
              </div>
              <p>Share your followers via live<br/> video streams for tokens</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon5} alt=""/>
              </div>
              <p>Monetize your popularity</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon6} alt=""/>
              </div>
              <p>Monetize the Virality<br/> of your invitations</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon2} alt=""/>
              </div>
              <p>Monetize your Sizzle videos<br/> on other Social Media</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon8} alt=""/>
              </div>
              <p>Do direct & product placement<br/> deals with brands</p>
            </div>
            <div className={classes.monetizeItem}>
              <div className={classes.imgWrap}>
                <img src={icon9} alt=""/>
              </div>
              <p>Monetize the shares<br/> of your videos</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionHowEarn