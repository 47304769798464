import React, {Component} from 'react'
import classes from './SectionProblem.module.scss'
import leftImage from './leftCircleClean.svg'
import leftImageMob from './leftCircleMob.svg'
import rightImage from './rightCircleClean_2.svg'
import anime from "animejs/lib/anime.es.js"
import BG from './BGBlur.png'
import {GlowParticles} from '../../components/GradientAnimation/GlowParticles'


const animateBG = () =>{
  let element = document.querySelector('.'+classes.BGCSS);


  anime({
    targets: element,
    easing: 'linear',
    background: {
      value: [
        'conic-gradient(from 78.15deg at 59.68% 45.6%, #C7D6F6 -0.44deg, #EDEDF4 -104.73deg, #E6CBF4 14.7deg, #CBF4F2 42.38deg, #CBF4F2 88.98deg, #F8FAFE 225.99deg, #C7D6F6 239.56deg, #EDEDF4 256.73deg)',
        'conic-gradient(from 318.15deg at 59.68% 45.6%, #C7D6F6 -0.44deg, #EDEDF4 16.73deg, #E6CBF4 254.7deg, #CBF4F2 293.38deg, #CBF4F2 428.98deg, #F8FAFE 465.99deg, #C7D6F6 480.56deg, #EDEDF4 496.73deg)',
        'conic-gradient(from 198.15deg at 59.68% 45.6%, #C7D6F6 -0.44deg, #EDEDF4 16.73deg, #E6CBF4 134.7deg, #CBF4F2 173.38deg, #CBF4F2 308.98deg, #F8FAFE 345.99deg, #C7D6F6 359.56deg, #EDEDF4 376.73deg)']
    },
    duration: 20000,
    loop: true,

  })
}

const animation = () =>{
  let test = document.querySelector('#leftCircle');
  let test2 = document.querySelector('#rightCircle');

  let leftCircle = test.querySelector('.leftCircle'),
    rightCircle = test2.querySelector('.rightCircle'),
    circlePart = test.querySelector('.circlePart'),
    innerCircle = test.querySelector('.innerCircle'),
    innerCircle2 = test2.querySelector('.innerCircle'),
    circleText = test.querySelector('.circleText'),
    circleText2 = test2.querySelector('.circleText'),
    outsideText = test.querySelectorAll('.outsideText'),
    outsideText2 = test.querySelectorAll('.outsideText2');

  let tl1 = anime.timeline({
    easing:'linear',
    duration: 500,
    loop: false,
  });

  tl1
    .add({
      targets: [leftCircle, rightCircle],
      strokeDasharray: '0 1177'
    })
    .add({
      targets: [innerCircle, innerCircle2],
      opacity: .4
    })
    .add({
      targets: circlePart,
      d:[
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 C192.323 34.919 220.78 30.6561 249.2 32.3254 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 C277.62 33.9947 305.382 41.5595 330.72 54.539 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C356.058 67.5185 378.418 85.6285 396.378 107.718 C356.058 67.5185 378.418 85.6285 396.378 107.718 C356.058 67.5185 378.418 85.6285 396.378 107.718 C356.058 67.5185 378.418 85.6285 396.378 107.718 C356.058 67.5185 378.418 85.6285 396.378 107.718 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C414.338 129.807 427.505 155.391 435.042 182.844 C414.338 129.807 427.505 155.391 435.042 182.844 C414.338 129.807 427.505 155.391 435.042 182.844 C414.338 129.807 427.505 155.391 435.042 182.844 C414.338 129.807 427.505 155.391 435.042 182.844 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C414.338 129.807 427.505 155.391 435.042 182.844 C442.579 210.298 444.321 239.019 440.156 267.182 C442.579 210.298 444.321 239.019 440.156 267.182 C442.579 210.298 444.321 239.019 440.156 267.182 C442.579 210.298 444.321 239.019 440.156 267.182 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C414.338 129.807 427.505 155.391 435.042 182.844 C442.579 210.298 444.321 239.019 440.156 267.182 C435.992 295.345 426.013 322.333 410.854 346.43 C435.992 295.345 426.013 322.333 410.854 346.43 C435.992 295.345 426.013 322.333 410.854 346.43 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C414.338 129.807 427.505 155.391 435.042 182.844 C442.579 210.298 444.321 239.019 440.156 267.182 C435.992 295.345 426.013 322.333 410.854 346.43 C395.694 370.528 375.686 391.207 352.102 407.153 C395.694 370.528 375.686 391.207 352.102 407.153 L237.168 237.167 L165.64 44.843Z'
        },
        {
          value: 'M165.64 44.843 C192.323 34.919 220.78 30.6561 249.2 32.3254 C277.62 33.9947 305.382 41.5595 330.72 54.539 C356.058 67.5185 378.418 85.6285 396.378 107.718 C414.338 129.807 427.505 155.391 435.042 182.844 C442.579 210.298 444.321 239.019 440.156 267.182 C435.992 295.345 426.013 322.333 410.854 346.43 C395.694 370.528 375.686 391.207 352.102 407.153 C328.518 423.099 301.874 433.963 273.864 439.054 L237.168 237.167 L165.64 44.843Z'
        }
      ]
    })
    .add({
      targets: [circleText , circleText2],
      opacity: 1
    })
    .add({
      targets: outsideText,
      opacity: 1
    })
    .add({
      targets: outsideText2,
      opacity: 1
    },'-=300')

}


const COLORS = [
 {r: 45, g: 74, b: 227},
 {r: 250, g: 255, b: 89},
 {r: 255, g: 104, b: 248},
 {r: 44, g: 209, b: 252},
 {r: 54, g: 233, b: 84},
]

class App {
  constructor(){
    this.canvas = document.querySelector('#BGGradient1');
    this.ctx = this.canvas.getContext('2d');
    this.pixelRatio = (window.devicePixelRatio > 1) ? 2 : 1;

    this.totalParticles = 25;
    this.particles = [];
    this.maxRadius = 200;
    this.minRadius = 100;


    window.addEventListener('resize', this.resize.bind(this), false);
    this.resize();

    window.requestAnimationFrame(this.animate.bind(this));
  }

  resize(){
    // this.stageWidth = document.body.clientWidth;
    // this.stageHeight = document.body.clientHeight;
    this.stageWidth = 200;
    this.stageHeight = 140;


    this.ctx.scale(this.pixelRatio, this.pixelRatio);

    this.createParticles();
  }

  createParticles(){
    let curColor = 0;
    this.particles = [];

    for(let i = 0; i< this.totalParticles; i++ ){
      const item = new GlowParticles(
        Math.random() * this.stageWidth,
        Math.random() * this.stageHeight,
        Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
        COLORS[curColor]
      );
      if(++curColor >= COLORS.length){
        curColor = 0;
      }

      this.particles[i] = item;


    }
  }

  animate(){
    window.requestAnimationFrame(this.animate.bind(this));

    this.ctx.clearRect(0,0,this.stageWidth, this.stageHeight);

    for(let i = 0; i < this.totalParticles; i++){
      const item = this.particles[i];
      item.animate(this.ctx, this.stageWidth, this.stageHeight);
    }
  }

}

class SectionProblem extends Component{




  componentDidMount() {

     // new App();
    // animateBG()
    let section = document.querySelector('#sectionProblem');

    if(!section.classList.contains('playMe')){
      window.addEventListener('scroll', function(){
        let metr = window.scrollY - section.offsetTop + 450;
        if(metr > 1 && !section.classList.contains('playMe')){
          section.classList.add('playMe');
          // animation();
        }
      })
    }
  }

  render(){
    return(
      <section className={classes.SectionProblem} id="sectionProblem">
        <div className={classes.BGCSS}>
          <img src={BG} alt=""/>
        </div>
        <div className="siteWidth">
          <h2 className={classes.title}>
            The Problem
          </h2>
          <div className={classes.description}>
            <b>53%</b> of the content creators are actually earning money
          </div>
          <div className={classes.innerWrapper}>
            <div className={classes.leftSide}>
              <div className={classes.smallTitle}>
                Non Tokenized Video Platforms
                <div className={classes.infoBlock}>
                  <div className={classes.hiddenBlock}>
                    <a href="https://growthbadger.com/blog-statistics/#:~:text=Monetization%20is%20a%20common%20discussion,and%20affiliate%20marketing%20(26%25).">Source: https://growthbadger.com...</a>
                  </div>
                  <div className={classes.icon}>

                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                    </svg>
                  </div>
                </div>
              </div>
              <div className={classes.imgWrap}>
                <img src={leftImage} className={classes.desktop} alt=""/>
                <img src={leftImageMob} className={classes.mobile} alt=""/>
                <span className={classes.mobText}>Video<br/> Creators<br/> Monetizer</span>
              </div>
            </div>
            <div className={classes.rightSide}>
              <div className={classes.smallTitle}>
                Sizzle
              </div>
              <div className={classes.imgWrap}>
                <img src={rightImage} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionProblem