import {Component} from "react";
import classes from "./SectionSiz5.module.scss";
import Button from "../../components/Button/Button";

class SectionSiz5 extends Component{
    render() {
        return(
            <section className={classes.section1 + ' illusionAnimation'} id="sectionSiz5" >
                <div className="siteWidth">
                    <div className={classes.innerWrapper}>
                        <div className={classes.h3}><h3>Sizzle's exponential viral growth </h3></div>
                    <div className={classes.blocks}>
                        <div className={classes.block}>
                            Sizzle can potentially reach
                            3 billion people
                        </div>
                        <div className={classes.block}>
                            Sizzle rewards its members
                            to create virality
                        </div>
                        <div className={classes.block}>
                            When a broad audience meets virality the community grow on steroids
                        </div>
                    </div>

                    <div className={classes.blocks}>
                        <div className={classes.block}>
                            We involve the everyday influencers to spread the word about Sizzle
                        </div>
                        <div className={classes.block}>
                            Sizzle don't have to buy advertising because the virality will disseminate the message about Sizzle
                        </div>
                        <div className={classes.block}>
                            Sizzle users paying in Sizzle utility tokens to promote themselves on the platform
                        </div>
                    </div>
                    <div className={classes.row}>
                        <Button text="Sale Options"/>
                    </div>

                    </div>
                </div>
            </section>
        )
    }
}
export default SectionSiz5