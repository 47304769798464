import React, {Component} from "react";
import icon1 from './icon1.svg';
import icon2 from './icon2.svg';
import calculator from './calculator.svg';
import video from './video.mp4'
import apple from './appstore.svg'
import google from './googlestore.svg'
import classes from './Section1.module.scss';
import frame from './frame1.png'
import Button from "../../components/Button/Button";


function calculatorBtn() {
    return (
        <div className={classes.profit}>
            <img src={calculator} alt=""/>
            <span className={classes.text}>Profit calculator</span>
        </div>
    )
}

// setTimeout(function(){
//   let phone = document.querySelector('#desktopPhone');
//   phone.classList.add(classes.run);
// }, 2000)

function replayVideo (){
  let video = document.querySelector('#firstBlockVideo');
  let replayBtn = document.querySelector('#replayVideo' );
  video.play();
  replayBtn.style.opacity = "0";
  video.addEventListener('ended', function(){
    replayBtn.style.opacity = "1";
  })
}

class Section1 extends Component {

    componentDidMount() {
        const phone = document.getElementById("hero-phone");
        const frameCount = 83;
        const currentFrame = index => (`./imgphone/${index.toString().padStart(5, '0')}.png`);
        let arrayImages = [];
        let video = document.getElementById("firstBlockVideo");

        for (let i = 0; i < frameCount; i++) {
            let img = new Image();
            img.src = currentFrame(i);
            arrayImages.push(img);

        }
        let i = 0;

        setTimeout(animPhone, 2500);

        function animPhone() {
            let interval = setInterval(function () {
                phone.innerHTML = "<img src='" + arrayImages[i].src + "'/>";
                i++;
                if (i === frameCount) {
                    video.style.opacity = "1";
                    clearInterval(interval); // Stopping the counter when reaching 0.
                }
            }, 25);
        }



    }

    render(props) {
        return (
            <section className={classes.section1 + ' illusionAnimation'} id="section1">
                <div className="siteWidth">
                    <div className={classes.innerWrapper}>
                        <div className={classes.leftSide}>
                            <h1 className={classes.title}>
                                Social media &<br className="s"/> blockchain combined
                            </h1>
                            <div className={classes.postTitle}>
                                Join now. Earn Sizzle tokens. No investment needed.
                            </div>
                            <div className={classes.videoWrapper}>
                                {/*<video  muted="muted" id="firstBlockVideo" preload="auto">*/}
                                {/*  <source src={video} type="video/mp4"/>*/}
                                {/*</video>*/}
                                {/*<div className={classes.btnWrap}>*/}
                                {/*  <button onClick={replayVideo}>Replay</button>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className={classes.rightSide}>
                            <div className={classes.heroPhone}>
                                <div id="hero-phone" className={classes.phoneFrame}> </div>
                                <video autoPlay  muted="muted" id="firstBlockVideo" preload="auto">
                                    <source src={video} type="video/mp4"/>
                                </video>
                                    <button id="replayVideo" className={classes.replayVideo} onClick={replayVideo}>Replay video</button>
                            </div>




                        </div>
                    </div>
                    <div className={classes.bottomSide}>
                        <div className={classes.btnWrap}>
                            <a href="#">
                                <img src={apple} alt=""/>
                            </a>
                            <a href="#">
                                <img src={google} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}


export default Section1
