import logo from './logo.svg'
import socials1 from './socials1.png'
import socials2 from './socials2.png'
import socials3 from './socials3.png'
import socials4 from './socials4.png'
import socials5 from './socials5.png'
import check from './check.svg'

import classes from './Section10.module.scss'
import React from "react";

function Section10(){
  return(
    <section className={classes.section10}>
      <div className="siteWidth">
        <div className={classes.innerWrapper}>
          <div className={classes.leftSide}>
            <h3 className={classes.title}>
              Popular<br /> Social Media Networks
            </h3>
            <div className={classes.socialItem}>
              <img src={socials1} alt=""/><div className={classes.infoBlock}>
              <div className={classes.hiddenBlock}>
                <a href="https://www.gobankingrates.com/money/business/how-much-is-tiktok-worth/">Source: https://www.gobankingrates.com</a>
              </div>
              <div className={classes.icon}>
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                </svg>
              </div>
            </div>

            </div>
            <div className={classes.socialItem}>
              <img src={socials2} alt=""/>
              <div className={classes.infoBlock}>
                <div className={classes.hiddenBlock}>
                  <a href="https://techcrunch.com/2021/04/19/clubhouse/">Source: https://techcrunch.com</a>
                </div>
                <div className={classes.icon}>
                  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className={classes.socialItem}>
              <img src={socials3} alt=""/>
              <div className={classes.infoBlock}>
                <div className={classes.hiddenBlock}>
                  <a href="https://www.investopedia.com/articles/personal-finance/030915/how-instagram-makes-money.asp">Source: https://www.investopedia.com</a>
                </div>
                <div className={classes.icon}>
                  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className={classes.socialItem}>
              <img src={socials4} alt=""/>
              <div className={classes.infoBlock}>
                <div className={classes.hiddenBlock}>
                  <a href="https://thebell.io/en/telegram-at-30bln/">Source: https://thebell.io</a>
                </div>
                <div className={classes.icon}>
                  <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                  </svg>
                </div>
              </div>
            </div>
            <div className={classes.socialItem}>
              <img src={socials5} alt=""/>
            </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.iconBlock}>
              <img src={logo} alt=""/>
            </div>
            <div className={classes.privilageItem}>
              <img src={check} alt=""/>
              <span>
               Entertaining Short Videos
              </span>
            </div>
            <div className={classes.privilageItem}>
              <img src={check} alt=""/>
              <span>
                Live Audio Chats & Rooms
              </span>
            </div>
            <div className={classes.privilageItem}>
              <img src={check} alt=""/>
              <span>
                Video Stories & Sharing
              </span>
            </div>
            <div className={classes.privilageItem}>
              <img src={check} alt=""/>
              <span>
                Messenger
              </span>
            </div>
            <div className={classes.bigInteger}>
              35x
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section10