import React, {Component} from "react";
import classes from "./SectionNft2.module.scss";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import './SectionNft2.css'
import video from './old2.mp4'
import Preloader from "../../components/Preloader/Preloader";

const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Move(), Sticky());


class SectionNft2 extends Component{

    componentDidMount() {
        const html = document.documentElement;
        const canvas = document.getElementById("hero-lightpass");
        const context = canvas.getContext("2d");
        const frameCount = 129;
        const currentFrame = index => (`./images/${index.toString().padStart(5, '0')}.jpg`);
        canvas.width = 1464;
        canvas.height = 1080;
        let arrayImages = [];

        if( window.document.body.clientWidth >= 1080 ){

        for (let i = 0; i < frameCount; i++) {
            let img = new Image();
            img.src = currentFrame(i);
            arrayImages.push(img);
        }



        arrayImages[0].onload = function () {
            context.drawImage(arrayImages[0], 0, 0);
        }
        var videoComeIn = document.getElementById("videoComeIn");

        function updateImage(index) {

            if (index < frameCount) {
                context.drawImage(arrayImages[index], 0, 0);
            }
            if (index >= frameCount) {
                videoComeIn.style.display = "block";
                videoComeIn.classList.add("videoplayed");
                if (videoComeIn.classList.contains('videoplayed')) {
                    videoComeIn.play();
                }
            }
            if (index <= frameCount-1) {
                videoComeIn.style.display = "none";
                videoComeIn.classList.remove("videoplayed");
                videoComeIn.classList.add("videopaused");
                if (videoComeIn.classList.contains('videopaused')) {
                    videoComeIn.pause();
                    videoComeIn.currentTime = 0;
                }
            }
        }

        window.addEventListener('scroll', () => {
            const scrollTop = html.scrollTop;
            const maxScrollTop = html.scrollHeight - window.innerHeight;
            const scrollFraction = (scrollTop / maxScrollTop) * 5.5;
            const frameIndex = Math.min(
                frameCount - 1,
                Math.ceil(scrollFraction * frameCount)
            );
            updateImage(frameIndex + 1);
        });

        }
    }
render() {
        return (
            <section className={classes.sectionNft2} id="sectionNft2" data-aos="fade-up">

                <div className={classes.widthVideoBlock} id="widthVideoBlock">
                    <ScrollContainer>
                        <ScrollPage page={0}>
                            <Animator animation={batch(Fade(), Sticky(50, 30), MoveOut(0, -850))}>
                                <div className={classes.positionBox}>
                                    <div className={classes.heading}>
                                        <p>NFT marketplace coming soon</p>
                                        <h1>Want to Auction your Video?</h1>
                                    </div>
                                </div>
                            </Animator>
                        </ScrollPage>
                    </ScrollContainer>

                    <div className={classes.videoOver} id="videoOver">
                    <div id="videoBox2" className={classes.videoWrapper}>
                        <canvas id="hero-lightpass" className={classes.canva}/>
                        <video autoPlay muted id="videoComeIn" poster="" style={{display: "none"}}  >
                            <source src={video} type='video/mp4'/>
                        </video>


                    </div>
                    </div>

                </div>

                <div className={classes.text} id="videoOverText">
                <p>
                    The new era of tokenized digital art is becoming a reality.
                    Influencers can profit by converting their videos to NFT.
                </p>
                </div>

                <div className={classes.mobileBlock}>
                    <div className={classes.headingMobile}>
                        <p>NFT marketplace coming soon</p>
                        <h1>Want to Auction your Video?</h1>
                    </div>
                    <img src="./images/00129.jpg" />
                    <p>
                        The new era of tokenized digital art is becoming a reality.
                        Influencers can profit by converting their videos to NFT.
                    </p>
                </div>
            </section>
        )
    }
}
export default SectionNft2