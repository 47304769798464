import classes from "./SectionSiz1.module.scss";
import {Component} from "react";
import Button from "../../components/Button/Button";


class SectionSiz1 extends Component {

    render(){
        return (
            <section className={classes.SectionSiz + ' illusionAnimation'} id="sectionSiz1" data-aos="fade-down">
                <div className="siteWidth">
                    <div className={classes.innerWrapper}>
                        <h1 className={classes.title}>SIZ Token.</h1>
                        <p className={classes.someText}>
                            SIZ is Sizzle's utility token that influencers and followers are using to reward each other on the platform.
                        </p>
                        <Button text="Sale Options"/>
                    </div>
                </div>
            </section>
        )
    }
}


export default SectionSiz1
