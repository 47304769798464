import {Component} from "react";
import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import Header from "../../containers/Header/Header";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import SectionSiz1 from "../../containers/SectionSiz1/SectionSiz1";
import SectionSiz4 from "../../containers/SectionSiz4/SectionSiz4";
import SectionSiz5 from "../../containers/SectionSiz5/SectionSiz5";
import SectionSiz3 from "../../containers/SectionSiz3/SectionSiz3";
import SectionSiz2 from "../../containers/SectionSiz2/SectionSiz2";
import Section8 from "../../containers/Section8/Section8";
import Section10 from "../../containers/Section10/Section10";

class Siz extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        EffectAnimation('sectionSiz1');
        EffectAnimation('sectionSiz5');
        EffectAnimation('SectionCounterForm');
    }



    render(){
        return(
            <>
                <Header/>
                <main>
                    <SectionSiz1/>
                    <SectionSiz2/>
                    <SectionSiz3/>
                    <SectionSiz4/>
                    <SectionSiz5/>
                    <Section8 />
                    <Section10 />
                    <SectionCounterForm />
                </main>
                <Footer/>
            </>
        )
    }
}

export default Siz;