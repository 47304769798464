import React, {Component} from 'react'
import classes from "./SectionRoadmap.module.scss"
import check from "./check.svg"
import rocket from "./rocket.svg"


function simpleAnimation(){
  let section = document.querySelector('#SectionRoadmap'),
      firstRoad = section.querySelector('.' + classes.firstRoad),
      secondRoad = section.querySelector('.' + classes.secondRoad),
      thirdRoad = section.querySelector('.' + classes.thirdRoad);


  window.addEventListener('scroll', function(e){
    if((window.scrollY - section.offsetTop + 450) > 1){
      firstRoad.classList.add(classes.run);
      secondRoad.classList.add(classes.run);
      thirdRoad.classList.add(classes.run);
    }

  });





}





class SectionRoadmap extends Component{
  componentDidMount() {
    simpleAnimation();
  }


  render(){
    return(
      <section className={classes.SectionRoadmap} id="SectionRoadmap">
        <div className="siteWidth">
          <h2 className={classes.title}>Development Roadmap</h2>
          <div className={classes.roadMapWrapper}>

            <div className={classes.firstRoad}>
              <div className={classes.date}>2019-2020</div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>2019</div>
                  <div className={classes.itemText}>
                    Business modeling<br/>
                    White paper & pitch deck<br/>
                    Executive board & advisory board<br/>
                    Partners (coding, tokenomics, licencing)</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={check} width="34" height="34" alt=""/>
                </div>
              </div>

              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>2020</div>
                  <div className={classes.itemText}>Jurisdiction & legal compliance
                    Technical analysis & feasibility study
                    Influencer & creator acquisition pilot
                    MVP coding</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={check} width="34" height="34" alt=""/>
                </div>
              </div>
            </div>


            <div className={classes.secondRoad}>
              <div className={classes.date}>2021</div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Q1 2021</div>
                  <div className={classes.itemText}>Platform prelaunch</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={check} width="34" height="34" alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>April, 2021</div>
                  <div className={classes.itemText}>The MVP version of the Sizzle mobile app is published on both App Store & Play Market. Influencers pre acquisition process launched.</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>May 2021</div>
                  <div className={classes.itemText}>The first official version of the Sizzle web platform and Sizzle mobile app. Worldwide influencers aqusition process launched.</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>July 2021</div>
                  <div className={classes.itemText}>Initial Token Offering.<br/>
                    Massive launch of the platform.</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Aug 2021</div>
                  <div className={classes.itemText}>Sizzle Audio Rooms feature released<br/>
                    in both App Store and Play Market.<br/>
                    Official Launch of Sizzle NFT Marketplace<br/>
                    on Web and Mobile</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Sep 2021</div>
                  <div className={classes.itemText}>Sizzle Live Video Streams feature released in both App Store and Play Market. Community Marketplace released on both Web and Mobile</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>

              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Nov 2021</div>
                  <div className={classes.itemText}>Advertising Engine here and move all other steps by one point below</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
            </div>


            <div className={classes.thirdRoad}>
              <div className={classes.date}>2022</div>
              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Jan 2022</div>
                  <div className={classes.itemText}>Sizzle Audio & Video Calls feature released in both App Store and Play Market</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Mar 2022</div>
                  <div className={classes.itemText}>Sizzle Messenger feature released
                    in both App Store and Play Market</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromRight].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Aug 2022</div>
                  <div className={classes.itemText}>Advertising engine released on both
                    Web and Mobile</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
              <div className={[classes.roadItem, classes.fromLeft].join(' ')}>
                <div className={classes.sideElement}>
                  <div className={classes.itemTitle}>Nov 2022</div>
                  <div className={classes.itemText}>Early adopters are incentivized by significantly larger token rewards. Earn more Sizzle tokens by starting early.</div>
                </div>
                <div className={classes.itemIcon}>
                  <img src={rocket} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionRoadmap