import classes from './SectionIto3.module.scss'
import SliderIto from "./slider/slider";
import AsNavFor from "./slider/slider";

function SectionIto3(){
    return(
        <section className={classes.Section3} style={{overflow: "hidden"}}>
            <div className="slider-ito siteWidth">
                <div data-aos="fade-left" className={classes.innerWrapper}>
                    <h2>Post ITO product
                        development&execution plan</h2>
                        <AsNavFor/>
                </div>
            </div>
        </section>
    )
}


export default SectionIto3