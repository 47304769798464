import classes from "./Button2.module.scss";

function Button2(props){
    return(
        <button className={classes.btn1} onClick={props.click} id={props.id}>
            {props.text}
        </button>
    )
}

export default Button2