import React, {Component} from 'react';
import classes from "./Counter2.module.scss";


class Counter2 extends Component{

  componentDidMount() {
    // Set the date we're counting down to
    let countDownDate = new Date("June 5, 2023 15:37:25").getTime();
    let day = -285;
    let hor = -285;
    let min = -285;
    let sec = -285;


    let section = this.props.section;

    // Update the count down every 1 second
    let x = setInterval(function () {

      if (sec == -568 || sec < -568) {
        sec = -285;
      }

      var exists = document.querySelector('#'+section);



      if(!exists){
        clearInterval(x);
        return;
      }

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById('days-'+section).innerHTML = days + "";
      document.getElementById('hours-'+section).innerHTML = hours + "";
      document.getElementById('minutes-'+section).innerHTML = minutes + "";
      document.getElementById('seconds-'+section).innerHTML = seconds + "";

      let secs = seconds / 60 * 100;
      let sec2 = 283 * secs / 100;
      let sectemp = sec - (283 - sec2);
      sec = sectemp - 3;
      let circleSeconds = sec + "";
      sec = -285;
      // document.getElementById('sec-'+section).setAttribute("stroke-dashoffset", circleSeconds)


      let mins = minutes / 60 * 100;
      let min2 = 283 * mins / 100;
      let mintemp = min - (283 - min2);
      min = mintemp - 3;
      let circleMinutes = min + "";
      min = -285;
      // document.getElementById('min-'+section).setAttribute("stroke-dashoffset", circleMinutes)

      let hors = hours / 60 * 100;
      let hor2 = 283 * hors / 100;
      let hortemp = hor - (283 - hor2);
      hor = hortemp - 3;
      let circleHours = hor + "";
      hor = -285;
      // document.getElementById('hor-'+section).setAttribute("stroke-dashoffset", circleHours)

      let dayss = days / 365 * 100;
      let dayss2 = 283 * dayss / 100;
      let daysstemp = hor - (283 - dayss2);
      day = daysstemp - 3;
      let circleDay = day + "";
      day = -285;
      // document.getElementById('day-'+section).setAttribute("stroke-dashoffset", circleDay)




      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById('demo-'+section).innerHTML = "EXPIRED";
      }
    }, 1000);
  }


  render(){
    return(
      <div className={classes.Counter2}>
        <div className={classes.intElement}>
          <div className={classes.integer} id={'days-'+this.props.section}>
            59
          </div>
          <div className={classes.name}>
            Days
          </div>
        </div>
        <div className={classes.intElement}>
          <div className={classes.integer} id={"hours-"+this.props.section}>
            59
          </div>
          <div className={classes.name}>
            Hours
          </div>
        </div>
        <div className={classes.intElement}>
          <div className={classes.integer} id={"minutes-"+this.props.section}>
            59
          </div>
          <div className={classes.name}>
            Minutes
          </div>
        </div>
        <div className={classes.intElement}>
          <div className={classes.integer} id={"seconds-"+this.props.section}>
            59
          </div>
          <div className={classes.name}>
            Seconds
          </div>
        </div>
      </div>
    )
  }
}



export default Counter2