import React, {Component} from "react";
import classes from "./SectionMobileApp5.module.scss";
import u1 from './u1.svg'
import u2 from './u2.svg'
import u4 from './u4.svg'

class SectionMobileApp5 extends Component {
    render() {
        return(
            <section className={classes.sectionMobileApp5}>
                <div className={classes.rowh}>
                    <h3>Here is how you earn <br/>
                        <span className={classes.blue}> early birds</span> Sizzle tokens</h3>
                </div>

                <div className={classes.row}>
                    <div className={classes.col}>
                        <div className={classes.users}>
                            <img src={u1}/>
                            <h5>You</h5>
                            <p>Receive token for joining the platform</p>
                            <img src={u2}/>
                            <h5>1st Level Friends </h5>
                            <p>Receive token for each friend who join</p>
                            <img src={u4}/>
                            <h5>2nd Level Friends</h5>
                            <p>Receive token for each friend of your friend who join</p>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.counter}>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionMobileApp5