import React, {Component} from "react";
import Header from "../../containers/Header/Header";
import Footer from "../../containers/Footer/Footer";
import Section1 from "../../containers/Section1/Section1";
import SectionFounders from '../../containers/SectionFounders/SectionFounders';
import SectionOurCommunity from '../../containers/SectionOurCommunity/SectionOurCommunity';
import SectionCalculator from '../../containers/SectionCalculator/SectionCalculator'
import SectionComp from '../../containers/SectionComp/SectionComp'
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import EffectAnimation from '../../components/EffectAnimation/EffectAnimation'
import SectionCompTwo from "../../containers/SectionCompTwo/SectionCompTwo";
import SectionContentType from "../../containers/SectionContentType/SectionContentType";

class InfluencersPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    EffectAnimation('section1');
    EffectAnimation('SectionCalculator');
    EffectAnimation('SectionCounterForm');
  }


  render(){
    return(
      <>
        <Header />
        <main>
          <Section1
            title="Convert your content & followers to real value"
            postTitle="The platform that will reward you for your creativity more than any other platform"
            btn={1}
          />
          <SectionFounders />
          <SectionOurCommunity />
          <SectionContentType />
          <SectionCalculator />
          <SectionComp />
          <SectionCompTwo />
          <SectionCounterForm
            remove={1}
          />
        </main>
        <Footer/>
      </>
    )
  }
}

export default InfluencersPage;