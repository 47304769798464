import {Component} from "react";
import classes from "./SectionSiz3.module.scss";
import svg1 from './1.svg'
import svg2 from './2.svg'
import svg3 from './3.svg'



function check1(){
    let checkOne = document.getElementById("checkOne");
    let checkTwo = document.getElementById("checkTwo");
    let checkThree = document.getElementById("checkThree");
    let checkText1 = document.getElementById("checkText1");
    let checkText2 = document.getElementById("checkText2");
    let checkText3 = document.getElementById("checkText3");
    checkOne.style.color = '#7101D3';
    checkOne.style.borderBottom = '3px solid #7101D3';
    checkTwo.style.color = 'black';
    checkTwo.style.borderBottom = 'none';
    checkThree.style.color = 'black';
    checkThree.style.borderBottom = 'none';

    checkText1.style.display = 'flex'
    checkText2.style.display = 'none'
    checkText3.style.display = 'none'
}
function check2(){
    let checkOne = document.getElementById("checkOne");
    let checkTwo = document.getElementById("checkTwo");
    let checkThree = document.getElementById("checkThree");
    let checkText1 = document.getElementById("checkText1");
    let checkText2 = document.getElementById("checkText2");
    let checkText3 = document.getElementById("checkText3");
    checkOne.style.color = 'black';
    checkOne.style.borderBottom = 'none';

    checkTwo.style.color = '#7101D3';
    checkTwo.style.borderBottom = '3px solid #7101D3';

    checkThree.style.color = 'black';
    checkThree.style.borderBottom = 'none';

    checkText1.style.display = 'none'
    checkText2.style.display = 'flex'
    checkText3.style.display = 'none'
}
function check3(){
    let checkOne = document.getElementById("checkOne");
    let checkTwo = document.getElementById("checkTwo");
    let checkThree = document.getElementById("checkThree");
    let checkText1 = document.getElementById("checkText1");
    let checkText2 = document.getElementById("checkText2");
    let checkText3 = document.getElementById("checkText3");
    checkOne.style.color = 'black';
    checkOne.style.borderBottom = 'none';

    checkTwo.style.color = 'black';
    checkTwo.style.borderBottom = 'none';

    checkThree.style.color = '#7101D3';
    checkThree.style.borderBottom = '3px solid #7101D3';

    checkText1.style.display = 'none'
    checkText2.style.display = 'none'
    checkText3.style.display = 'block'
}


class SectionSiz3 extends Component {
    render() {
        return (
          <section className={classes.sectionSiz3}>
              <div className="siteWidth">
                  <div className={classes.innerWrapper}>
                      <h2 className={classes.title}>The Utility Value of SIZ token</h2>
                      <p className={classes.postTitle}>Sizzle users paying in Sizzle utility tokens to promote
                          themselves on the platform</p>
                  </div>
                  <div className={classes.buttons}>
                      <div onClick={check1} id="checkOne" style={{borderBottom: '3px solid #7101D3', color: '#7101D3'}}>How influencers can earn tokens
                      </div>
                      <div onClick={check2} id="checkTwo">How viewers
                          can earn tokens
                      </div>
                      <div onClick={check3} id="checkThree">How tokens can be
                          spend and used
                      </div>
                  </div>
                  <div className={classes.innerWrapper}>
                      <div className={classes.content} id="checkText1">
                          <div className={classes.list}>
                              <ul>
                                  <li>Monetize your video Views Instantly on the Sizzle Platform</li>
                                  <li>Monetize your video Views Instantly off  the Sizzle Platform</li>
                                  <li>Monetize your Videos Share</li>
                                  <li>Invite your audience to Join Sizzle </li>
                                  <li>Sell Joint Live Video Streams to your followers</li>
                                  <li>Monetize your popularity on Sizzle Platform</li>
                                  <li>Monetize the Virality of your invitations?</li>
                                  <li>Sell your Videos as NFT on Sizzle NFT Marketplace</li>
                                  <li>Do direct Deals with Brands </li>
                                  <li>Sell product placements</li>
                              </ul>
                          </div>
                          <div className={classes.table}>
                              <img src={svg1}/>
                          </div>
                      </div>

                      <div className={classes.content} id="checkText2" style={{display: 'none'}}>
                          <div className={classes.list}>
                              <ul>
                                  <li>View Sizzle Videos on Sizzle</li>
                                  <li>View Sizzle Videos off Sizzle</li>
                                  <li>Share the videos with your Friends</li>
                                  <li>Invite your friends to join Sizzle</li>
                                  <li>Become a content creator and monetize
                                      on popularity and Virality
                                  </li>
                              </ul>
                          </div>
                          <div className={classes.table}>
                              <img src={svg2}/>
                          </div>
                      </div>

                      <div className={classes.content} id="checkText3" style={{display: 'none'}}>
                          <div className={classes.table3}>
                              <img src={svg3}/>
                          </div>
                      </div>

                  </div>
              </div>
          </section>
        )
    }
}
export default SectionSiz3