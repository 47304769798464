import classes from './IndexPageBlock.module.scss'

function IndexPageBlock(){
  return(
    <>
      <h2 className={classes.title}>
      How did we conclude the assumptions presented above
      </h2>
      <div className={classes.postTitle}>
        The number of Sizzle tokens is limited to 100 million only
        Potential Sizzle's Ecosystem Valuation in 1-2 years = $10 billion
        With 100 million of Sizzle tokens the est. value of 1 token is $100
    </div>
    </>
  )
}

export default IndexPageBlock