import React, { Component } from "react";
import Slider from "react-slick";
import classes from './slider.module.scss'
import slide1 from './slide1.png'
import slide2 from './slide2.png'
import slide3 from './slide3.png'
import slide4 from './slide4.png'
import './slider.css'
import cheked from './cheked.svg'

export default class AsNavFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        return (
            <div>
                <Slider
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                >
                    <div>
                        <div className="itemSlide">
                            <div className={classes.textSlide}>
                                <div className={classes.textLeft}>
                                    <h4>Live Audio Room</h4>
                                    <ul className={classes.ulTextLeft}>
                                        <li>Create audio conference bridge</li>
                                        <li>Invite friends & followers</li>
                                        <li>Broadcast to your audience</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.imgSlide}>
                                <img src={slide1}/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="itemSlide">
                            <div className={classes.textSlide}>
                                <div className={classes.textLeft}>
                                    <h4>Live Audio Room</h4>
                                    <ul className={classes.ulTextLeft}>
                                        <li>Create audio conference bridge</li>
                                        <li>Invite friends & followers</li>
                                        <li>Broadcast to your audience</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.imgSlide}>
                                <img src={slide2}/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="itemSlide">
                            <div className={classes.textSlide}>
                                <div className={classes.textLeft}>
                                    <h4>Live Audio Room</h4>
                                    <ul className={classes.ulTextLeft}>
                                        <li>Create audio conference bridge</li>
                                        <li>Invite friends & followers</li>
                                        <li>Broadcast to your audience</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.imgSlide}>
                                <img src={slide3}/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="itemSlide">
                            <div className={classes.textSlide}>
                                <div className={classes.textLeft}>
                                    <h4>Live Audio Room</h4>
                                    <ul className={classes.ulTextLeft}>
                                        <li>Create audio conference bridge</li>
                                        <li>Invite friends & followers</li>
                                        <li>Broadcast to your audience</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.imgSlide}>
                                <img src={slide4}/>
                            </div>
                        </div>
                    </div>


                </Slider>
                {/*<h4>Second Slider</h4>*/}
                <Slider
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={4}
                    swipeToSlide={true}
                    focusOnSelect={true}
                className={classes.dotItemSlider}>
                    <div className="dotItem">
                        <div className="cheked"><img src={cheked}/> </div>
                        <div className="dotText">
                            <h6>Jul -Aug <span>2021</span></h6>
                            <p className="dotTextB">Sizzle Live Audio Room</p>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                        </div>
                        <div className="dotActive">
                            <h6>Live Audio Broadcasting</h6>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                            <p className="dateDotText">Jul-Aug 2021</p>
                        </div>
                    </div>

                    <div className="dotItem">
                        <div className="cheked"><img src={cheked}/> </div>
                        <div className="dotText">
                            <h6>Jul -Aug <span>2021</span></h6>
                            <p className="dotTextB">Sizzle Live Audio Room</p>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                        </div>
                        <div className="dotActive">
                            <h6>Live Audio Broadcasting</h6>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                            <p className="dateDotText">Jul-Aug 2021</p>
                        </div>
                    </div>

                    <div className="dotItem">
                        <div className="cheked"><img src={cheked}/> </div>
                        <div className="dotText">
                            <h6>Jul -Aug <span>2021</span></h6>
                            <p className="dotTextB">Sizzle Live Audio Room</p>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                        </div>
                        <div className="dotActive">
                            <h6>Live Audio Broadcasting</h6>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                            <p className="dateDotText">Jul-Aug 2021</p>
                        </div>
                    </div>

                    <div className="dotItem">
                        <div className="cheked"><img src={cheked}/> </div>
                        <div className="dotText">
                            <h6>Jul -Aug <span>2021</span></h6>
                            <p className="dotTextB">Sizzle Live Audio Room</p>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                        </div>
                        <div className="dotActive">
                            <h6>Live Audio Broadcasting</h6>
                            <p>Now you can do live audio meetings with your friends on Sizzle.</p>
                            <p className="dateDotText">Jul-Aug 2021</p>
                        </div>
                    </div>

                </Slider>
            </div>
        );
    }
}