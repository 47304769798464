import React, {Component} from 'react';
import classes from "./Counter.module.scss";


class Counter extends Component{

  componentDidMount() {
    // Set the date we're counting down to
    let countDownDate = new Date("June 5, 2023 15:37:25").getTime();
    let day = -285;
    let hor = -285;
    let min = -285;
    let sec = -285;


    let section = this.props.section;

    // Update the count down every 1 second
    let x = setInterval(function () {

      if (sec == -568 || sec < -568) {
        sec = -285;
      }

      var exists = document.querySelector('#'+section);


      if(!exists){
        clearInterval(x);
        return;
      }

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      document.getElementById('days-'+section).innerHTML = days + "";
      document.getElementById('hours-'+section).innerHTML = hours + "";
      document.getElementById('minutes-'+section).innerHTML = minutes + "";
      document.getElementById('seconds-'+section).innerHTML = seconds + "";

      let secs = seconds / 60 * 100;
      let sec2 = 283 * secs / 100;
      let sectemp = sec - (283 - sec2);
      sec = sectemp - 3;
      let circleSeconds = sec + "";
      sec = -285;
      document.getElementById('sec-'+section).setAttribute("stroke-dashoffset", circleSeconds)


      let mins = minutes / 60 * 100;
      let min2 = 283 * mins / 100;
      let mintemp = min - (283 - min2);
      min = mintemp - 3;
      let circleMinutes = min + "";
      min = -285;
      document.getElementById('min-'+section).setAttribute("stroke-dashoffset", circleMinutes)

      let hors = hours / 60 * 100;
      let hor2 = 283 * hors / 100;
      let hortemp = hor - (283 - hor2);
      hor = hortemp - 3;
      let circleHours = hor + "";
      hor = -285;
      document.getElementById('hor-'+section).setAttribute("stroke-dashoffset", circleHours)

      let dayss = days / 365 * 100;
      let dayss2 = 283 * dayss / 100;
      let daysstemp = hor - (283 - dayss2);
      day = daysstemp - 3;
      let circleDay = day + "";
      day = -285;
      document.getElementById('day-'+section).setAttribute("stroke-dashoffset", circleDay)




      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById('demo-'+section).innerHTML = "EXPIRED";
      }
    }, 1000);
  }


  render(){
    return(
      <div className={classes.counterRadialDiv}>

        <div className={classes.radialDiv}>
          <svg viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" stroke="#ffffff40" strokeWidth="3" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>
            <circle id={'day-'+this.props.section} cx="50" cy="50" r="45" stroke="#fff" strokeWidth="3.5" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>

            <text fill="#fff" id={'days-'+this.props.section} x="50" y="50" textAnchor="middle" dy="7" fontSize="38">00</text>
            <text fill="#fff" x="50" y="65" textAnchor="middle" dy="7" fontSize="14">Days</text>
          </svg>

        </div>
        <div className={classes.radialDiv}>

          <svg viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" stroke="#ffffff40" strokeWidth="3" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>
            <circle id={"hor-"+this.props.section} cx="50" cy="50" r="45" stroke="#fff" strokeWidth="3.5" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>

            <text fill="#fff" id={"hours-"+this.props.section} x="50" y="50" textAnchor="middle" dy="7" fontSize="38">00</text>
            <text fill="#fff" x="50" y="65" textAnchor="middle" dy="7" fontSize="14">Hours</text>
          </svg>
        </div>
        <div className={classes.radialDiv}>
          <svg viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" stroke="#ffffff40" strokeWidth="3" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>
            <circle id={"min-"+this.props.section} cx="50" cy="50" r="45" stroke="#fff" strokeWidth="3.5" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>

            <text fill="#fff" id={"minutes-"+this.props.section} x="50" y="50" textAnchor="middle" dy="7" fontSize="38">00</text>
            <text fill="#fff" x="50" y="65" textAnchor="middle" dy="7" fontSize="14">Minutes</text>
          </svg>
        </div>
        <div className={classes.radialDiv}>
          <svg viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="45" stroke="#ffffff40" strokeWidth="3" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>
            <circle id={"sec-"+this.props.section} cx="50" cy="50" r="45" stroke="#fff" strokeWidth="3.5" d="M50 10
           a 40 40 0 0 1 0 80
           a 40 40 0 0 1 0 -80" fill="none" strokeDasharray="285"/>

            <text fill="#fff" id={"seconds-"+this.props.section} x="50" y="50" textAnchor="middle" dy="7" fontSize="38">00</text>
            <text fill="#fff" x="50" y="65" textAnchor="middle" dy="7" fontSize="14">Seconds</text>
          </svg>

        </div>
      </div>
    )
  }
}



export default Counter