import classes from './IndexPageBlock2.module.scss'

function IndexPageBlock2(){
  return(
    <>
      <h3 className={classes.title}>
        Early adopters are incentivized by&nbsp;significantly larger token rewards
      </h3>
    </>
  )
}

export default IndexPageBlock2