import classes from './SectionCounterForm.module.scss'
import IndexPageBlock from '../../components/IndexPageBlock/IndexPageBlock'
import IndexPageBlock2 from '../../components/IndexPageBlock2/IndexPageBlock2'
import Counter from '../../components/Counter/Counter'
import Counter2 from '../../components/Counter2/Counter2'


// document.addEventListener("DOMContentLoaded", function(e) {
//     console.log(e);
//     console.log('loaded');
// },

function SectionCounterForm(props) {
    return (
        <section className={classes.SectionCounterForm} id="SectionCounterForm">
            <div className="siteWidth">
                {props.remove ? IndexPageBlock2() : IndexPageBlock()}
                <div className={classes.constantBlock}>
                    <h4 className={classes.constTitle}>Earn more SIZZLE Tokens by&nbsp;starting early</h4>
                    <div className={classes.constPostTitle}><span className={classes.textColor}>Platinum tier</span> ends up in:</div>
                    <div className={classes.counter} >
                        <Counter2
                            section="SectionCounterForm"
                        />
                    </div>
                    <div className={classes.formEmail}>
                        <h6>Become an early Adopter</h6>
                        <p>If you became a believer - join now.<br/>
                            If you are not a believer yet, join anyway
                            because you have nothing to lose.
                        </p>
                        <form className={classes.form}>
                            <input className={classes.inputEmail} type="email" placeholder="Enter your email"/>
                            <button className={classes.submit} type="submit">Get private invitation</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SectionCounterForm