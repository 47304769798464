import React, {Component} from "react";
import classes from './SectionFounders2.module.scss'
import founder1 from './founder1.png'
import founder2 from './founder2.png'
import founder3 from './founder3.png'
import founder4 from './founder4.png'
import founder5 from './founder5.png'
import founder6 from './founder6.png'
import founder7 from './founder7.png'
import founder8 from './founder8.png'
import founder9 from './founder9.png'
import founder10 from './founder10.png'
import founder11 from './founder11.png'
import founder13 from './founder13.png'
import founder14 from './founder14.png'
import scope from './scope.png'


const smallAnim = () => {
  let section = document.querySelector('#SectionFounders2');
  section.style.marginTop  = '-110px';
}


class SectionFounders2 extends Component {

  componentDidMount = () => {
    window.addEventListener('scroll',smallAnim)
  }
  componentWillUnmount = () => {
    window.removeEventListener('scroll', smallAnim);
  }

  render(){
    return (
      <section className={classes.SectionFounders2} id="SectionFounders2">
        <h2 className={classes.title}>
          Sizzle founder's startups were featured in:
        </h2>
        <div className={classes.inputWrapper}>
          <div className={classes.item}>
            <img src={founder1} width="192" height="26" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder2} width="52" height="24" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder3} width="45" height="30" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder4} width="38" height="30" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder5} width="57" height="18" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder14} width="173" height="18" alt=""/>
          </div>
          <span className={classes.breaker}>

        </span>
          <div className={classes.item}>
            <img src={founder6} width="65" height="20" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder7} width="78" height="40" alt=""/>
          </div>
          <div className={classes.item} style={{marginTop:'-5px'}}>
            <img src={founder8} width="91" height="31" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder9} width="93" height="31" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder10} width="72" height="36" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder13} width="75" height="25" alt=""/>
          </div>
          <div className={classes.item}>
            <img src={founder11} width="88" height="25" alt=""/>
          </div>
        </div>
        <div className={classes.imgWrap}>
          <img src={scope} alt=""/>
        </div>
      </section>
    )
  }
}

export default SectionFounders2