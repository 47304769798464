import {Component} from "react";
import classes from "./SectionSiz4.module.scss";
import u1 from "../SectionMobileApp5/u1.svg";
import u2 from "../SectionMobileApp5/u2.svg";
import u4 from "../SectionMobileApp5/u4.svg";
import './SectionSiz4.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init();



class SectionSiz4 extends Component{



    count = (int) =>{
        if (int == 0) {
            let level1 = document.getElementById("slider").value;
            let level2 = document.getElementById("slider2").value;
            let price = document.getElementById("slider3").value;
            let referrals = document.getElementById("referrals");
            let earnings = document.getElementById("earnings");

            let l1 = parseFloat(level1);
            document.getElementById("slider2").value = l1 * 1.8;
            let l2 = parseFloat(level2);

        referrals.innerHTML = l1 + l2;
        let p = parseInt(price, 10);
        let sunEarns = p * (l1 + l2);
        let sEarnings =  sunEarns.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        earnings.innerHTML = sEarnings + "x";


            let fill = document.getElementsByClassName("fill")[0];
            let fill2 = document.getElementsByClassName("fill2")[0];
            let fill3 = document.getElementsByClassName("fill3")[0];

            fill.style.width = level1/2 + "%";
            fill2.style.width = level2/5 + "%";
            fill3.style.width = price + "%";

        }
        if (int == 1) {
            let level1 = document.getElementById("slider").value;
            let level2 = document.getElementById("slider2").value;
            let price = document.getElementById("slider3").value;
            let referrals = document.getElementById("referrals");
            let earnings = document.getElementById("earnings");

            let l1 = parseFloat(level1);
            let l2 = parseFloat(level2);
            document.getElementById("slider").value = l2 / 1.8;


            referrals.innerHTML = l1 + l2;
            let p = parseInt(price, 10);
            let sunEarns = p * (l1 + l2);
            let sEarnings =  sunEarns.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            earnings.innerHTML = sEarnings + "x";

            let fill = document.getElementsByClassName("fill")[0];
            let fill2 = document.getElementsByClassName("fill2")[0];
            let fill3 = document.getElementsByClassName("fill3")[0];

            fill.style.width = level1/2 + "%";
            fill2.style.width = level2/5 + "%";
            fill3.style.width = price + "%";
        }
            //
            const range = document.getElementById("slider");
            const bubble = document.getElementsByClassName("bubble")[0];

            range.addEventListener("input", () => {
                this.setBubble(range, bubble);
            });
            this.setBubble(range, bubble);
        //
        const range2 = document.getElementById("slider2");
        const bubble2 = document.getElementsByClassName("bubble2")[0];

        range.addEventListener("input", () => {
            this.setBubble(range2, bubble2);
        });
        this.setBubble(range2, bubble2);
        //
        const range3 = document.getElementById("slider3");
        const bubble3 = document.getElementsByClassName("bubble3")[0];

        range.addEventListener("input", () => {
            this.setBubbleToken(range3, bubble3);
        });
        this.setBubbleToken(range3, bubble3);

    }


    setBubble = (range, bubble) => {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        bubble.innerHTML = val;

        bubble.style.left = `calc(${newVal}% + (${40 - newVal * 0.20}px))`;
    }

    setBubbleToken = (range, bubble) => {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));
        bubble.innerHTML = val + "x";

        bubble.style.left = `calc(${newVal}% + (${40 - newVal * 0.20}px))`;
    }

    range = (int) =>{
        this.count(int);
    }

    autoRange = () => {
        this.autoRangeStart();
    }


    autoRangeStart = () => {
        let level1 = document.getElementById("slider").value;
        let level2 = document.getElementById("slider2").value = 128;
        let price = document.getElementById("slider3").value = 18;
        let fill = document.getElementsByClassName("fill")[0];
        let fill2 = document.getElementsByClassName("fill2")[0];
        let fill3 = document.getElementsByClassName("fill3")[0];


        fill.style.width = level1/2 + "%";
        fill2.style.width = level2/5 + "%";
        fill3.style.width = price + "%";


    }


    componentDidMount() {
        this.autoRangeStart();
        this.count();
    }
    componentDidUpdate() {
        this.autoRangeStart();
        this.count()
    }




    render() {
        return(

            <section className={classes.sectionSiz4}>
                <div className={classes.rowh}>
                    <h3>Sizzle 2 levels referral program calculator</h3>
                </div>

                <div className={classes.row}>
                    <div className={classes.col}>
                        <div className={classes.users}>
                            <img src={u1}/>
                            <h5>You</h5>
                            <p>Receive token for joining the platform</p>
                            <img src={u2}/>
                            <h5>1st Level Friends </h5>
                            <p>Receive token for each friend who join</p>
                            <img src={u4}/>
                            <h5>2nd Level Friends</h5>
                            <p>Receive token for each friend of your friend who join</p>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.counter} id="counter">
                            <h6>Referral Calculator</h6>
                            <p>You can move the slider and select the number of connected<br/>users by each referral level to calculate token eranings </p>

                            <div className={classes.value}>1st Level </div>
                            <div className="slider-container">
                                <output className="bubble"></output>
                                <span class="bar"><span class="fill" data-aos="slide-right"
                                                        data-aos-anchor-placement="center-bottom"></span></span>
                                <input id="slider" className="slider" type="range" min="0" max="200" defaultValue="71" onChange={() => this.range(0)}/>
                            </div>
                            <div className={classes.rangeNum}><span>0</span> <span>200</span></div>

                            <div className={classes.value}>2nd Level </div>
                            <div className="slider-container">
                                <output className="bubble2"></output>
                            <span className="bar2"><span className="fill2" data-aos="slide-right"
                                                         data-aos-anchor-placement="center-bottom"></span></span>
                            <input id="slider2" className="slider2" type="range" min="0" max="500" defaultValue="0" onChange={() => this.range(1)}/>
                            </div>
                            <div className={classes.rangeNum}><span>0</span> <span>500</span></div>

                            <div className={classes.value}>Token Value</div>
                            <div className="slider-container">
                                <output className="bubble3"></output>
                            <span className="bar3"><span className="fill3" data-aos="slide-right"
                                                         data-aos-anchor-placement="center-bottom"></span></span>
                            <input id="slider3" className="slider3" type="range" min="0" max="100" defaultValue="0" onChange={() => this.range(0)}/>
                            </div>
                            <div className={classes.rangeNum}><span>0</span> <span>100x</span></div>



                            <div className={classes.refs}>
                                Referrals Joined
                            </div>
                            <div id="referrals" className={classes.refsNum}>
                                443
                            </div>
                            <div className={classes.earns}>
                                Cumulative Earnings
                            </div>
                            <div  id="earnings" className={classes.earnsNum}>
                                10,000,000x
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionSiz4