import React, {Component} from 'react'
import classes from "./SectionIto1.module.scss";
import Counter from '../../components/Counter/Counter'
// import CounterIto from "../../components/CounterIto/CounterIto";
// import CalculateTimeLeftIto from "../../components/CounterIto/CounterIto";

// export const initCounter = (section) =>{
//     return Counter(section)
// }

class SectionIto1 extends Component {

  render(){
      return (
          <section className={classes.section1 + ' illusionAnimation'} id="section1ito" data-aos="fade-down">
              <div className="siteWidth">
                  <div className={classes.innerWrapper}>
                      <div className={classes.textblock1}>
                          <h1>Initial Token Offering </h1>
                          <p>Start date: 10th July 2021</p>
                      </div>
                      <div className={classes.counterIto}>
                          <Counter
                            section="section1ito"
                          />
                  {/*{CalculateTimeLeftIto()}*/}
                  {/*{initCounter('section1ito')}*/}
                      </div>
                      <div className={classes.buttonBlock}>
                          <button>Become an investor</button>
                      </div>
                      <div className={classes.textblock3}>
                          <p>Sizzle token total supply: 100 million</p>
                          <p>Tokens reserved for ITO: 1 million</p>
                      </div>
                  </div>
              </div>
          </section>
      )
  }
}


export default SectionIto1
