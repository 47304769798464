import classes from './SectionComp.module.scss'

function SectionComp(){
  return(
    <section className={classes.SectionComp} id='SectionComp'>
      <div className="siteWidth">
        <div className={classes.innerWrapper}>
          <div className={classes.leftSide}>
            <div className={classes.title}>
              Sizzle
            </div>
            <div className={classes.wrapper}>
              <ul>
                <li>
                  Sizzle is a community that stores the value in a utility token
                </li>
                <li>Community and content are assets that have value</li>
                <li>Sizzle tokens can be obtained without
                  paying money </li>
                <li>As time passes by & community grows, the value of the token grows</li>
                <li>The fixed amount of tokens, cannot be diluted by issuing more tokens</li>
                <li>A united community caring to jointly protect the token from manipulation</li>
              </ul>
            </div>
          </div>
          <div className={classes.word}>vs</div>
          <div className={classes.rightSide}>
            <div className={classes.title}>
              ICO & crypto
            </div>
            <div className={classes.wrapper}>
              <ul>
                <li>Security Token is only used for trading purposes</li>
                <li>ICO has only perceived value with no assets behind</li>
                <li>Only bought for money</li>
                <li>Can grow or crash due to speculation</li>
                <li>Sometimes no control over the amount in the market</li>
                <li>A lot of speculators and no community protection</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default SectionComp