import classes from "./SectionCompareTable.module.scss"
import icon1 from './icon1.png'
import icon2 from './icon2.png'
import icon3 from './icon3.png'
import icon4 from './icon4.png'
import yes from './yes.svg'
import no from './no.svg'


function SectionCompareTable(){
  return(
    <section className={classes.SectionCompareTable} id="SectionCompareTable">
      <div className="siteWidth">
        <h2 className={classes.title}>Compare Sizzle monetization to other platforms</h2>
        <div className={classes.tableWrap}>
          <div className={classes.tableBlock}>
            <div className={classes.tHeader}>
              <div className={classes.tRow}>
                <div className={classes.tCell}>

                </div>
                <div className={classes.tCell}><img width="85" src={icon1} alt=""/></div>
                <div className={classes.tCell}><img width="128" src={icon2} alt=""/></div>
                <div className={classes.tCell}><img width="118" src={icon3} alt=""/></div>
                <div className={classes.tCell}><img width="96" src={icon4} alt=""/></div>
              </div>
            </div>
            <div className={classes.tBody}>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Direct monetization for content
                </div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>Yes</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>No</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>No</span></div>
                <div className={classes.tCell}>Tiktok creator's fund</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Views monetization
                </div>
                <div className={classes.tCell}>instant monetization of each view</div>
                <div className={classes.tCell}>you start monetizing views only after you channel reaches 4000 hours of watch time is achieved</div>
                <div className={classes.tCell}>IGTV Ads monetization (55% of the total Ads revenue), only after you reach 1000 followers</div>
                <div className={classes.tCell}>TikTok Creators Fund (2-4 cents per 1000 views)</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Monetization outside the platform
                </div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>available on other platofrms and on the Web</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>not available</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>not available</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>not available</span></div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Video format
                </div>
                <div className={classes.tCell}>any length for Horizontal video & up to 3 minutes vertical videos</div>
                <div className={classes.tCell}>Only horizontal videos of any length</div>
                <div className={classes.tCell}>60 minutes vertical on IGTV, 1 min square</div>
                <div className={classes.tCell}>only 1 min vertical</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  How you can go viral
                </div>
                <div className={classes.tCell}>The algorithms on the platform are designed in a way so people are incentivised to engage with as much content as possible to earn Sizzle tokens. You need 10X less of an effort to get viral on Sizzle as opposed to TikTok</div>
                <div className={classes.tCell}>A lot of competition across all the Youtube users. You can only go viral if you get to recommendations which require a huge following base and TV-quality of the content.</div>
                <div className={classes.tCell}>It is very hard to go viral because Instagram adjusted algorithms to gain as many paid views as possible and tremendously cut off organic reach.</div>
                <div className={classes.tCell}>It is possible to go viral if you have a very high quality content. The algorithms are being adjusted a couple of times already to cut off organic views. It is now becoming harder and harder to go viral.</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Platform Fees
                </div>
                <div className={classes.tCell}>10%</div>
                <div className={classes.tCell}>32%</div>
                <div className={classes.tCell}>there is no video monetization on the platform</div>
                <div className={classes.tCell}>there is no video monetization on the platform</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  CPM cost
                </div>
                <div className={classes.tCell}>10X</div>
                <div className={classes.tCell}>$2-$4</div>
                <div className={classes.tCell}>$2 – $20</div>
                <div className={classes.tCell}>2-4 cents</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Can advertise brands
                </div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Convert your Video to NFT
                </div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>no</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>no</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>no</span></div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Categorization of the Videos
                </div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={yes} width="25" height="25" alt=""/>yes</span></div>
                <div className={classes.tCell}><span><img src={no} width="25" height="25" alt=""/>no</span></div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  How you can acquire more followers
                </div>
                <div className={classes.tCell}>You promote yourself on the platform using Sizzle tokens that you can earn with no investment needed</div>
                <div className={classes.tCell}>Organically, paid campaigns in Fiat, Paid integrations with other Bloggers</div>
                <div className={classes.tCell}>Organically, Paid integrations with other bloggers, Paid traffic, Paid giveaways</div>
                <div className={classes.tCell}>Organically, Paid integrations with other bloggers, Paid traffic, Paid giveaways</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  CPC cost
                </div>
                <div className={classes.tCell}>No annoying Ads. The algorithms on the platform are designed in a way that people can monetize their contents and activities and earn Sizzle tokens</div>
                <div className={classes.tCell}>$0.10 to $0.30</div>
                <div className={classes.tCell}>average: $0.20 – $1.00 | up to $3 for highly competitive industries</div>
                <div className={classes.tCell}>$1 or less per click</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  Basic requirement needed for monetization
                </div>
                <div className={classes.tCell}>Sign up, become a verified member of the community, invite friends to join</div>
                <div className={classes.tCell}>Join some special monetiztion program. Must grow your audience to at least 1,000 subscribers before you can apply for monetization</div>
                <div className={classes.tCell}>Join some special monetiztion program. Must grow your account to a at least 1000 unique followers before you can earn from the IGTV Ads program</div>
                <div className={classes.tCell}>You need at least 100K authentic follwers and 100K authentic views before you can join the Creators Fund program</div>
              </div>
              <div className={classes.tRow}>
                <div className={classes.tCell}>
                  % of creators that get monetized
                </div>
                <div className={classes.tCell}>100%</div>
                <div className={classes.tCell}>12%</div>
                <div className={classes.tCell}>46.50%</div>
                <div className={classes.tCell}>less than 20% are eligible for the Fund program</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionCompareTable