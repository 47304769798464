import icon1 from './icon1.png'
import icon2 from './icon2.png'

import classes from './Section8.module.scss'
import React from "react";

function Section8(){
  return (
    <section className={classes.section8} id="section8">
      <div className="siteWidth">
        <h2 className={classes.title}>
          Compare tokenized and non-tokenized social media
        </h2>
        <div className={classes.compareBlock}>
          <div className={classes.compareItem}>
            <div className={classes.itemIcon}>
              <img src={icon1} alt=""/>
            </div>
            <div className={classes.itemTitle}>
              non-tokenized community
            </div>
            <div className={classes.itemContent}>
              <div className={classes.topContent}>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Date of Launch:
                    <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://en.wikipedia.org/wiki/Reddit">Source: https://en.wikipedia.org...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
    </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                    23 June 2005
                  </span>
                </div>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Monthly Users:
                      <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://backlinko.com/reddit-users#reddit-monthly-active-users">Source: https://backlinko.com...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
    </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                    568,675,000
                  </span>
                </div>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Market Cap:
                    <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://www.businessofapps.com/data/reddit-statistics/">Source: https://www.businessofapps.com...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
    </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                    $6,000,000,000
                  </span>
                </div>
              </div>
              <div className={classes.bottomContent}>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    Value of One User
                  </span>
                  <span className={classes.contentItemValue}>
                    $10.55
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.footnote}>*the trademark is a property of the respective owners.</div>
          </div>
          <div className={classes.compareItem}>
            <div className={classes.itemIcon}>
              <img src={icon2} alt=""/>
            </div>
            <div className={classes.itemTitle}>
              tokenized community
            </div>
            <div className={classes.itemContent}>
              <div className={classes.topContent}>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Date of Launch:
                    <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://en.wikipedia.org/wiki/Steemit">Source: https://en.wikipedia.org...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
    </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                    March 24, 2016
                  </span>
                </div>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Monthly Users:
                    <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://en.wikipedia.org/wiki/Steemit">Source: https://en.wikipedia.org...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
    </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                     1,238,717
                  </span>
                </div>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    <span style={{ position: 'relative' }}>Market Cap:
                    <div className={classes.infoBlock}>
                      <div className={classes.hiddenBlock}>
                        <a href="https://coinmarketcap.com/currencies/steem/">Source: https://coinmarketcap.com...</a>
                      </div>
                      <div className={classes.icon}>
                        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM6.6975 4.941L5.9475 8.46975C5.895 8.72475 5.96925 8.8695 6.1755 8.8695C6.321 8.8695 6.54075 8.817 6.69 8.685L6.624 8.997C6.40875 9.2565 5.934 9.4455 5.52525 9.4455C4.998 9.4455 4.77375 9.129 4.91925 8.45625L5.47275 5.85525C5.52075 5.6355 5.47725 5.556 5.2575 5.50275L4.91925 5.442L4.98075 5.15625L6.69825 4.941H6.6975ZM6 4.125C5.80109 4.125 5.61032 4.04598 5.46967 3.90533C5.32902 3.76468 5.25 3.57391 5.25 3.375C5.25 3.17609 5.32902 2.98532 5.46967 2.84467C5.61032 2.70402 5.80109 2.625 6 2.625C6.19891 2.625 6.38968 2.70402 6.53033 2.84467C6.67098 2.98532 6.75 3.17609 6.75 3.375C6.75 3.57391 6.67098 3.76468 6.53033 3.90533C6.38968 4.04598 6.19891 4.125 6 4.125Z" fill="inherit"/>
                        </svg>
                      </div>
                    </div>
                    </span>
                  </span>
                  <span className={classes.contentItemValue}>
                    $459,260,000
                  </span>
                </div>
              </div>
              <div className={classes.bottomContent}>
                <div className={classes.contentItem}>
                  <span className={classes.contentItemTitle}>
                    Value of One User
                  </span>
                  <span className={classes.contentItemValue}>
                    $370.75
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.footnote}>*the trademark is a property of the respective owners.</div>
          </div>
        </div>
        <div className={classes.bottomLevel}>
          The Value of one <span className={classes.compName}>Steemit</span> user is <span className={classes.bgBig}>35x</span> higher than <span className={classes.compName}>Reddit</span> user.
        </div>
      </div>
    </section>
  )
}

export default Section8