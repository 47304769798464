import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import Header from "../../containers/Header/Header";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import {Component} from "react/cjs/react.production.min";
import SectionIto1 from "../../containers/SectionIto1/SectionIto1";
import SectionIto2 from "../../containers/SectionIto2/SectionIto2";
import SectionIto3 from "../../containers/SectionIto3/SectionIto3";
import SectionIto4 from "../../containers/SectionIto4/SectionIto4";
import SectionIto5 from "../../containers/SectionIto5/SectionIto5";
import SectionIto6 from "../../containers/SectionIto6/SectionIto6";

class Ito extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        EffectAnimation('section1ito');
        EffectAnimation('SectionCounterForm');
    }

    render(){
        return(
            <>
                <Header />
                <main>
                    <SectionIto1 />
                    {/*<SectionIto2 />*/}
                    {/*<SectionIto3 />*/}
                    <SectionIto4 />
                    <SectionIto5 />
                    <SectionIto6 />
                    <SectionCounterForm
                      remove={1}
                    />
                </main>
                <Footer/>
            </>
        )
    }
}

export default Ito;