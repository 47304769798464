import classes from './SectionFeatures.module.scss'
import image1 from './img1.png'
import image2 from './img2.png'
import image3 from './img3.png'
import image4 from './img4.png'
import image5 from './img5.png'


const SectionFeatures = () => {
  return(
    <section className={classes.SectionFeatures}>
      <div className="siteWidth">
        <h2 className={classes.title}>
          Features
        </h2>
        <div className={classes.featuresBlock}>
          <div className={classes.featureItem}>
            <div className={classes.imgBlock}>
              <div className={classes.imgWrap}>
                <img src={image1} alt=""/>
              </div>
            </div>
            <div className={classes.textBlock}>
              <div className={classes.itemTitle}>
                Endless amount of videos
              </div>
              <ul className={classes.itemList}>
                <li>Explore endless viral videos</li>
                <li>Edit your own videos with creative effects</li>
                <li>Enhance your content creator abilities</li>
              </ul>
            </div>

          </div>
          <div className={classes.featureItem}>
            <div className={classes.imgBlock}>
              <div className={classes.imgWrap}>
                <img src={image2} alt=""/>
              </div>
            </div>
            <div className={classes.textBlock}>
              <div className={classes.itemTitle}>
                Live Audio Room
              </div>
              <ul className={classes.itemList}>
                <li>Create audio conference bridge</li>
                <li>Invite friends & followers</li>
                <li>Broadcast to your audience</li>
              </ul>
            </div>

          </div>
          <div className={classes.featureItem}>
            <div className={classes.imgBlock}>
              <div className={classes.imgWrap}>
                <img src={image3} alt=""/>
              </div>
            </div>
            <div className={classes.textBlock}>
              <div className={classes.itemTitle}>
                Group Video Streaming
              </div>
              <ul className={classes.itemList}>
                <li>Stream together with other influencers</li>
                <li>Monetize your popularity</li>
                <li>Earn tokens for sharing your fan base</li>
              </ul>
            </div>

          </div>
          <div className={classes.featureItem}>
            <div className={classes.imgBlock}>
              <div className={classes.imgWrap}>
                <img src={image4} alt=""/>
              </div>
            </div>
            <div className={classes.textBlock}>
              <div className={classes.itemTitle}>
                Messenger
              </div>
              <ul className={classes.itemList}>
                <li>Stay in touch with your followers</li>
                <li>Send bulk messages to your audience</li>
                <li>Engage one-on-one with other influencers</li>
              </ul>
            </div>

          </div>
          <div className={classes.featureItem}>
            <div className={classes.imgBlock}>
              <div className={classes.imgWrap}>
                <img src={image5} alt=""/>
              </div>
            </div>
            <div className={classes.textBlock}>
              <div className={classes.itemTitle}>
                Audio & Video Calls
              </div>
              <ul className={classes.itemList}>
                <li>Call to your friends in Sizzle</li>
                <li>Look videos together on calls</li>
                <li>Use Masks & Filters</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFeatures