import icon1 from "./icon1.svg"
import icon2 from "./icon2.svg"
import classes from './SectionContentType.module.scss'

const SectionContentType = () => {
  return(
    <section className={classes.SectionContentType} id="SectionContentType">
      <div className="siteWidth">
        <h2 className={classes.title}>
          Two types of Content you can upload on Sizzle as an influencer
        </h2>
        <div className={classes.innerWrapper}>
          <div className={classes.contentItem}>
            <div className={classes.imgWrap}>
              <img src={icon1} alt=""/>
            </div>
            <div className={classes.textWrap}>
              <div className={classes.itemTitle}>Long horizontal videos
                like on YouTube*</div>
              <div className={classes.itemText}>Available only for accredited influencers</div>
            </div>
            <div className={classes.footnote}>
              * all trademarks are the properties of the respective owners.
            </div>
          </div>
          <div className={classes.contentItem}>
            <div className={classes.imgWrap}>
              <img src={icon2} alt=""/>
            </div>
            <div className={classes.textWrap}>
              <div className={classes.itemTitle}>Short videos like on TikTok*</div>
              <div className={classes.itemText}>Available for everyone</div>
            </div>
            <div className={classes.footnote}>
              * all trademarks are the properties of the respective owners.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionContentType