import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import SectionTeam from "../../containers/SectionTeam/SectionTeam";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import {Component} from "react/cjs/react.production.min";
import Header from "../../containers/Header/Header";
import classes from './TeamPage.module.scss'

class Team extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        EffectAnimation('SectionCounterForm');
    }


    render(){
        return(
            <>
                <Header
                  addClass="black"
                />
                <main className={classes.main}>
                    <SectionTeam/>
                    <SectionCounterForm
                      remove={1}
                    />
                </main>
                <Footer/>
            </>
        )
    }
}

export default Team;