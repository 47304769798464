import React, {Component} from 'react';
import classes from "./SectionProduct.module.scss";
import video1 from "./video1.mp4"
import video2 from "./video2.mp4"
import video3 from "./video3.mp4"
import video4 from "./video4.mp4"
import video5 from "./video5.mp4"
import video6 from "./video6.mp4"
import left1 from "./Endless Videos .svg";
import left2 from "./Live Audio Rooms.svg";
import left3 from "./Power Stream.svg";
import right1 from "./Live Video Streaming.svg";
import right2 from "./Group Video Calls.svg";
import right3 from "./Messages.svg";
import anime from 'animejs'
import './variables'
import video from "../SectionOurCommunity/openLapeTop.mp4";
import Slider from "react-slick/lib";

const addingClass = () => {
  let section = document.querySelector('#SectionProduct');
  let metr = window.scrollY - section.offsetTop + 450;
  let firstVideo  = document.querySelector('#someTry');

  if(metr > 1 && !section.classList.contains('playMe')){
    section.classList.add('playMe');
    firstVideo.play();
  }
}

class SectionProduct extends Component{


  handleClick = (int, e) => {




  function videoPlay(videoArr, videoNumber) {

    // let replayBtn = document.querySelector('.'+classes.btnWrap);

    for( let i = 0; i < videoArr.length; i++){
      if(videoArr[i].classList.contains('active')){
        videoArr[i].pause();
        videoArr[i].style.zIndex = 0;
        videoArr[i].classList.remove('active');
      }
    }

    videoNumber.style.zIndex = global.index;
    videoNumber.classList.add('active');
    // videoNumber.load();
    videoNumber.volume = 0;
    videoNumber.play();

    for( let i = 0; i < videoArr.length; i++){
      if(!videoArr[i].classList.contains('active')){
        videoArr[i].load();
      }
    }



    if(global.index < 100){
      global.index++;
    }
    else{
      global.index = 1
    }


    // replayBtn.style.opacity = "0";
    // videoNumber.addEventListener('ended', function(){
    //   replayBtn.style.opacity = "1";
    // })

  }

  if(document.body.clientWidth > 990){

    let current = e.currentTarget,
        // svg = current.querySelector('svg'),
        // path = svg.querySelector('.bigOne'),
        // circles = svg.querySelector('circle'),
        video = document.querySelector('#someTry'),
        // source = video.querySelector('source'),
        video1 = document.querySelector('.video1'),
        video2 = document.querySelector('.video2'),
        video3 = document.querySelector('.video3'),
        video4 = document.querySelector('.video4'),
        video5 = document.querySelector('.video5'),
        video6 = document.querySelector('.video6'),
        videoArr = [video1, video2, video3, video4, video5, video6],
      strokeOffset;

  for(let i = 0; i < videoArr.length; i++){
    videoArr[i].pause();
  }

    switch(int){
      case 1 :
        strokeOffset = '-180';
        videoPlay(videoArr, video1);
      break;
      case 2 :
        strokeOffset = '-180';
        videoPlay(videoArr, video2);
      break;
      case 3 :
        strokeOffset = '-180';
        videoPlay(videoArr, video6);
      break;
      case 4 :
        strokeOffset = '-180';
        videoPlay(videoArr, video4);
      break;
      case 5 :
        strokeOffset = '-180';
        videoPlay(videoArr, video5);
      break;
      case 6 :
        strokeOffset = '-180';
        videoPlay(videoArr, video6);
      break;
    }

    // let tl1 = anime.timeline({
    //   easing:'linear',
    //   duration: 500,
    //   loop: false,
    // });
    // let tl2 = anime.timeline({
    //   easing:'linear',
    //   duration: 20000,
    //   loop: true,
    //   delay: 500,
    //   autoplay: false
    // });
    //
    // tl2
    //   .add({
    //     targets: path,
    //     strokeDashoffset: 10000,
    //   });
    //
    // tl1
    //   .add({
    //     targets: circles,
    //     opacity: 1
    //   })
    //   .add({
    //     targets: path,
    //     strokeDashoffset: strokeOffset,
    //
    //   });

    let allItems = document.querySelectorAll('.' + classes.productItem);

    for(let i = 0; i < allItems.length; i++){

      // if(allItems[i].classList.contains(classes.active)){
        // svg = allItems[i].querySelector('svg');
        // path = svg.querySelector('path');
        // circles = svg.querySelectorAll('circle');


        // for(let j = 0; j < circles.length; j++){
        //   circles[j].setAttribute('opacity', '0');
        // }
        // path.style.strokeDasharray = 180;
        // path.style.strokeDashoffset = -180;
      // }
      allItems[i].classList.remove(classes.active);

    }

    e.currentTarget.classList.add(classes.active);

  }
  else {
    let allItems = document.querySelectorAll('.' + classes.productItem),
        video1 = document.querySelector('.video1'),
        video2 = document.querySelector('.video2'),
        video3 = document.querySelector('.video3'),
        video4 = document.querySelector('.video4'),
        video5 = document.querySelector('.video5'),
        video6 = document.querySelector('.video6'),
        videoArr = [video1, video2, video3, video4, video5, video6];

    for(let i = 0; i < videoArr.length; i++){
      videoArr[i].pause();
    }

    switch (int) {
      case 1 :
        videoPlay(videoArr, video1);
        this.videoScroll(video1);
        break;
      case 2 :
        videoPlay(videoArr, video2);
        this.videoScroll(video2);
        break;
      case 3 :
        videoPlay(videoArr, video3);
        this.videoScroll(video3);
        break;
      case 4 :
        videoPlay(videoArr, video4);
        this.videoScroll(video4);
        break;
      case 5 :
        videoPlay(videoArr, video5);
        this.videoScroll(video5);
        break;
      case 6 :
        videoPlay(videoArr, video6);
        this.videoScroll(video6);
        break;
    }


    for (let i = 0; i < allItems.length; i++) {

      if (allItems[i].classList.contains(classes.active)) {

      }
      allItems[i].classList.remove(classes.active);

    }
    e.currentTarget.classList.add(classes.active);



  }
};

  replayShownVideo = () => {
    let videos = document.querySelectorAll('video');
    let replayBtn = document.querySelector('.'+classes.btnWrap);

    replayBtn.style.opacity = "0";

    for(var i = 0; i < videos.length; i++ ){
      if(videos[i].classList.contains('active')){
        videos[i].play();
        videos[i].addEventListener('ended', function(){
          replayBtn.style.opacity = "1";
        })
      }
    }
  };

  videoScroll = (video) => {
    video.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };




  componentDidMount(){
    let section = document.querySelector('#SectionProduct');

    if(!section.classList.contains('playMe')){
      window.addEventListener('scroll', addingClass)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', addingClass)
  }



  render(){

    const sliderSettings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      autoplay: 10000,
      slidesToShow: 2,
      slidesToScroll: 1,
      dotsClass:  `slick-dots ${classes.slick_dots}`,
      responsive: [
        {
          breakpoint: 765,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '20px',
          }
        }
      ]
    };

  return(
      <section className={classes.SectionProduct} id="SectionProduct">
        <div className="siteWidth">
          <h2 className={classes.title}>
            The Product
          </h2>
          <div className={classes.innerWrapper}>
            <div className={classes.leftSide}>
              <div className={[classes.productItem, classes.active].join(' ')} onClick={(e) => this.handleClick(1, e)}>
                <div className={classes.itemTitle}>
                  Endless Videos
                </div>
                <ul>
                  <li>Explore endless viral videos</li>
                  <li>Edit your own videos with creative effects</li>
                  <li>Enhance your content creator abilities</li>
                </ul>
              </div>
              <div className={classes.productItem} onClick={(e) => this.handleClick(2, e)}>
                <div className={classes.itemTitle}>
                  Live Audio Rooms
                </div>
                <ul>
                  <li>Create audio conference bridge</li>
                  <li>Invite friends & followers</li>
                  <li>Broadcast to your audience</li>
                </ul>
              </div>
              <div className={classes.productItem} onClick={(e) => this.handleClick(3, e)}>
                <div className={classes.itemTitle}>
                  Your own video broadcasting
                </div>
                <ul>
                  <li>Broadcast your own talents </li>
                  <li>Invite friends & followers to the broadcast</li>
                  <li>Earn tokens from benefiting viewers</li>
                </ul>
              </div>
            </div>
            <div className={classes.center}>
              <div className={classes.imgWrap}>
                <video id="someTry" loop="loop" className="video1 active" style={{zIndex:1}}>
                  <source src={video1} type="video/mp4"/>
                </video>
                <video className="video2" loop="loop" style={{zIndex:0}}>
                  <source src={video2} type="video/mp4"/>
                </video>
                <video className="video3" loop="loop" style={{zIndex:0}}>
                  <source src={video3} type="video/mp4"/>
                </video>
                <video className="video4" loop="loop" style={{zIndex:0}}>
                  <source src={video4} type="video/mp4"/>
                </video>
                <video className="video5" loop="loop" style={{zIndex:0}}>
                  <source src={video5} type="video/mp4"/>
                </video>
                <video className="video6" loop="loop" style={{zIndex:0}}>
                  <source src={video6} type="video/mp4"/>
                </video>
              </div>
              {/*<div className={classes.btnWrap}>*/}
              {/*  <button onClick={this.replayShownVideo}>Replay</button>*/}
              {/*</div>*/}
            </div>
            <div className={classes.rightSide}>
              <div className={classes.productItem} onClick={(e) => this.handleClick(4, e)}>
                <div className={classes.itemTitle}>
                  Group Video Streaming
                </div>
                <ul>
                  <li>Stream together with other influencers </li>
                  <li> Monetize your popularity</li>
                  <li> Earn tokens for sharing your fan base </li>
                </ul>
              </div>
              <div className={classes.productItem} onClick={(e) => this.handleClick(5, e)}>
                <div className={classes.itemTitle}>
                  Sell your videos
                </div>
                <ul>
                  <li>Convert your video to NFT </li>
                  <li>List your videos on NFT marketplace </li>
                  <li>Sell your NFT to advertisers</li>
                </ul>
              </div>
              <div className={classes.productItem} onClick={(e) => this.handleClick(6, e)}>
                <div className={classes.itemTitle}>
                  Messages
                </div>
                <ul>
                  <li>Stay in touch with your followers </li>
                  <li>Send bulk messages to your audience </li>
                  <li>Engage one-on-one with other influencers</li>
                </ul>
              </div>
            </div>
            <div className={classes.bottomSlider}>
              <Slider {...sliderSettings} className={classes.SliderBlock}>
                <div className={[classes.productItem, classes.active].join(' ')} onClick={(e) => this.handleClick(1, e)}>
                  <div className={classes.itemTitle}>
                    Endless Videos
                  </div>
                  <ul>
                    <li>Explore endless viral videos</li>
                    <li>Edit your own videos with creative effects</li>
                    <li>Enhance your content creator abilities</li>
                  </ul>
                </div>
                <div className={classes.productItem} onClick={(e) => this.handleClick(2, e)}>
                  <div className={classes.itemTitle}>
                    Live Audio Rooms
                  </div>
                  <ul>
                    <li>Create audio conference bridge</li>
                    <li>Invite friends & followers</li>
                    <li>Broadcast to your audience</li>
                  </ul>
                </div>
                <div className={classes.productItem} onClick={(e) => this.handleClick(3, e)}>
                  <div className={classes.itemTitle}>
                    Your own video broadcasting
                  </div>
                  <ul>
                    <li>Broadcast your own talents </li>
                    <li>Invite friends & followers to the broadcast</li>
                    <li>Earn tokens from benefiting viewers</li>
                  </ul>
                </div>
                <div className={classes.productItem} onClick={(e) => this.handleClick(4, e)}>
                  <div className={classes.itemTitle}>
                    Group Video Streaming
                  </div>
                  <ul>
                    <li>Stream together with other influencers </li>
                    <li> Monetize your popularity</li>
                    <li> Earn tokens for sharing your fan base </li>
                  </ul>
                </div>
                <div className={classes.productItem} onClick={(e) => this.handleClick(5, e)}>
                  <div className={classes.itemTitle}>
                    Sell your videos
                  </div>
                  <ul>
                    <li>Convert your video to NFT </li>
                    <li>List your videos on NFT marketplace </li>
                    <li>Sell your NFT to advertisers</li>
                  </ul>
                </div>
                <div className={classes.productItem} onClick={(e) => this.handleClick(6, e)}>
                  <div className={classes.itemTitle}>
                    Messages
                  </div>
                  <ul>
                    <li>Stay in touch with your followers </li>
                    <li>Send bulk messages to your audience </li>
                    <li>Engage one-on-one with other influencers</li>
                  </ul>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionProduct