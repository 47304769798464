import classes from './SectionCalculator.module.scss'
import React, {Component} from "react";

class SectionCalculator extends Component{

  count = () =>{

    let audience = document.getElementById("audience").value.replace(/[^0-9]/g, '');
    let rate = document.getElementById("rate").value;
    let price = document.getElementById("price").value;

    let resultDiv = document.getElementById("result");

    let result = audience * ((parseFloat(rate)/100)/10) * parseFloat(price.replace(/[^0-9.]/g, ''));

    resultDiv.innerHTML =  "$" + Math.round(result).toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ',');

    let audience2 = document.getElementById("audience");
    audience2.value =  audience.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ',');

    let rate2 = document.getElementById("rate");
    rate2.value = rate.replace(/[^0-9]/g, '').split('%')[0].toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ',') + "%";

    let price2 = document.getElementById("price");
    price2.value =  "$" + price.replace(/[^0-9.]/g, '') ;

  }



  range = () =>{
    let price = document.getElementById("price");
    let range = document.getElementById("range").value;
    // console.log(range)
    if (range > 0 && range < 100) {
      price.value = 1 + ".0€";
    } else if (range >= 100 && range < 200) {
      price.value = 1.30 + "€";
    } else if (range >= 200 && range < 300) {
      price.value = 1.69 + "€";
    } else if (range >= 300 && range < 400) {
      price.value = 2.20 + "€";
    } else if (range >= 400 && range < 500) {
      price.value = 2.86 + "€";
    } else if (range >= 500 && range < 600) {
      price.value = 3.71 + "€";
    } else if (range >= 600 && range < 700) {
      price.value = 4.83 + "€";
    } else if (range >= 700 && range < 800) {
      price.value = 6.27 + "€";
    } else if (range >= 800 && range < 900) {
      price.value = 8.16 + "€";
    } else if (range >= 900 && range < 1000) {
      price.value = 10.16 + "€";
    } else if (range >= 1000 && range < 1100) {
      price.value = 13.79 + "€";
    } else if (range >= 1100 && range < 1200) {
      price.value = 17.92 + "€";
    } else if (range >= 1200 && range < 1300) {
      price.value = 23.30 + "€";
    } else if (range >= 1300 && range < 1400) {
      price.value = 30.29 + "€";
    }

    this.count();
  }



  componentDidMount() {

    this.count();

    // if(window.innerWidth > 1200){
    //
    //   window.addEventListener('scroll', function(e){
    //     let section = document.querySelector('#SectionCalculator');
    //
    //     console.log();
    //
    //     if(window.pageYOffset > 3250 && window.pageYOffset < 5080){
    //       var test = (window.pageYOffset - section.offsetTop + section.offsetHeight) / 100 * 2.4 ;
    //
    //
    //       section.setAttribute("style", "background-position: center " + test + "%")
    //     }
    //
    //   })
    // }

  }
  componentDidUpdate() {
    this.count()
  }


  render() {
    return(
      <section className={classes.SectionCalculator} id='SectionCalculator'>

        <div className={classes.wrap}>
          <div className={classes.textWrap}>
            <h3>Token Earnings Calculator</h3>
            <p className={classes.pText}>Calculate your potential Sizzle earnings based on how
              good you spread the word around your social media</p>
            <div className={classes.marginTop}>
              <div className={classes.inputs}>
                <div className={classes.input1}>
                  <p>
                    You current Audience Size
                    <span>(on any social media platform: TikTok, Instagram, YouTube)</span>
                  </p>
                  <input type="text" id="audience" className={classes.audience} name="audience" onChange={() => this.count()} defaultValue="10,000,000"/>
                </div>
                <div className={classes.input2}>
                  <p>
                    Estimated Conversion Rate
                    <span>to the Sizzle joins</span>
                  </p>
                  <input type="text" id="rate" className={classes.rate} name="rate" onChange={() => this.count()} defaultValue="5%"/>
                </div>
                <div className={classes.input3}>
                  <p>Forecasted Token Price
                    <span>in USDT</span>
                  </p>
                  <input type="text" id="price" className={classes.price} name="price" onChange={() => this.count()}
                         defaultValue="1.0€"/>
                </div>
              </div>


              <div className={classes.rangeTitle}>
                <p>Date selection <span>in monthly breakdown</span></p>

                <input type="range" id="range" className={classes.range} name="range" min="1" max="1400" step="1"
                       defaultValue="1" onChange={() => this.range()}/>


                <div className={classes.mounths}>
                  <div>May 21</div>
                  <div>Jun 21</div>
                  <div>Jul 21</div>
                  <div>Aug 21</div>
                  <div>Sep 21</div>
                  <div>Oct 21</div>
                  <div>Nov 21</div>
                  <div>Dec 21</div>
                  <div>Jan 22</div>
                  <div>Feb 22</div>
                  <div>Mar 22</div>
                  <div>Apr 22</div>
                  <div>May 22</div>
                  <div>Jun 22</div>
                </div>
              </div>
              <div className={classes.Cumulative}>
                <h5>Cumulative Earnings:</h5>
              </div>
              <div className={classes.earnings} id="result">

              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }


}



export default SectionCalculator