import classes from "./SectionIto2.module.scss";
import svg1 from './1.svg'
import svg2 from './2.svg'
import svg3 from './3.svg'

function showIto1() {
    let ito1 = document.getElementById("box1");
    let ito2 = document.getElementById("box2");
    let btnIto1 = document.getElementById("btnIto1");
    let btnIto2 = document.getElementById("btnIto2");

    btnIto1.setAttribute("style", "color: #fff; background: #7101D3; border: 0.896552px solid #5C43C2;");
    btnIto2.setAttribute("style", "color: #000; background: #fff; border: none;");
    ito1.setAttribute("style", "display: block;");
    ito2.setAttribute("style", "display: none;");
}
function showIto2() {
    let ito1 = document.getElementById("box1");
    let ito2 = document.getElementById("box2");
    let btnIto1 = document.getElementById("btnIto1");
    let btnIto2 = document.getElementById("btnIto2");

    btnIto1.setAttribute("style", "color: #000; background: #fff; border: none;");
    btnIto2.setAttribute("style", "color: #fff; background: #7101D3; border: 0.896552px solid #5C43C2;");
    ito1.setAttribute("style", "display: none;");
    ito2.setAttribute("style", "display: block;");
}

function SectionIto2 (){
    return(
        <section className={classes.section2}>
            <div className="siteWidth" data-aos="fade-up">
                <div className={classes.innerWrapper}>
                    <h2 className={classes.title}>Pre ITO Progress</h2>
                    <div className={classes.btnIto}>
                        <button id="btnIto1" onClick={showIto1} style={{
                            color: '#fff',
                            background: '#7101D3',
                            border: '0.896552px solid #5C43C2',
                        }}>2019 - Q1 2021</button>
                        <button id="btnIto2" onClick={showIto2}>Q2 2021</button>
                    </div>
                    <div className={classes.allWrap} id="box1" style={{display: 'block'}} >
                        <div className={classes.wrapBox}>
                            <div className={classes.years}>
                                <div className={classes.year}>2019 </div>
                                <div className={classes.year}> 2020 </div>
                                <div className={classes.year}>Q1 2021</div>
                            </div>
                            <div className={classes.blueBoxBox}>
                                <div className={classes.blueBox}>
                                    <img className={classes.svgIcons} src={svg1}/>
                                    <ul className={classes.ul}>
                                        <li>
                                            Business modeling
                                        </li>
                                        <li>
                                            White paper & pitch deck
                                        </li>
                                        <li>
                                            Executive board & advisory board
                                        </li>
                                        <li>
                                            Partners (coding, tokenomics, licencing)
                                        </li>
                                    </ul>
                                </div>
                                <div className={classes.blueBox}>
                                    <img className={classes.svgIcons} src={svg2}/>
                                    <ul className={classes.ul}>
                                        <li>
                                            Jurisdiction & legal compliance
                                        </li>
                                        <li>
                                            Technical analysis & feasibility study
                                        </li>
                                        <li>
                                            Influencer & creator acquisition pilot
                                        </li>
                                        <li>
                                            MVP coding
                                        </li>
                                    </ul>
                                </div>
                                <div className={classes.blueBox}>
                                    <img className={classes.svgIcons} src={svg3}/>
                                    <ul className={classes.ul}>
                                        <li>
                                            MVP design and Architecture
                                        </li>
                                        <li>
                                            500 wireframes to demo the SIE app
                                        </li>
                                        <li>
                                            Networks launch
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className={classes.allWrap} id="box2" style={{display: 'none'}}>


                        <div className={classes.wrapBox}>
                            <div className={classes.years}>
                                <div className={classes.year}>April,  2021 </div>
                                <div className={classes.year}>May,  2021 </div>
                                <div className={classes.year}>Jun,  2021 </div>
                            </div>
                            <div className={classes.blueBoxBox}>
                                <div className={classes.blueBox}>

                                    <ul className={classes.ul}>
                                        <li>
                                            The MVP version of the Sizzle mobile app is published on both App Store & Play Market.

                                        </li>
                                        <li>
                                            Influencers pre aqusition process launched.
                                        </li>
                                    </ul>
                                </div>
                                <div className={classes.blueBox}>
                                    <ul className={classes.ul}>
                                        <li>
                                            The first official version of the Sizzle web platform and Sizzle mobile app is published.

                                        </li>
                                        <li>
                                            Worldwide influencers aqusition process launched.
                                        </li>
                                    </ul>
                                </div>
                                <div className={classes.blueBox}>
                                    <ul className={classes.ul}>
                                        <li>
                                            Initial Token Offering.
                                        </li>
                                        <li>
                                            Massive launch of the platform.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default SectionIto2