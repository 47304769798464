import classes from "./SectionAllDevices.module.scss"
import devices from "./allDevices.png"
import React, {Component} from "react";
import anime from "animejs/lib/anime.es.js"
import BG from './BGBlur.png'


const animateBG = () =>{
  let element = document.querySelector('.'+classes.BGCSS);


  anime({
    targets: element,
    easing: 'linear',
    background: {
      value: [
        'conic-gradient(from 146.23deg at 55.1% 31.2%, #E6CBF4 -31.88deg, #EDEDF4 16.73deg, #F8FAFE 46.87deg, #CBEFF4 155.62deg, #C7D6F6 264.38deg, #E6CBF4 328.12deg, #EDEDF4 376.73deg)',
        'conic-gradient(from 26.23deg at 55.1% 31.2%, #E6CBF4 -151.88deg, #EDEDF4 -104.73deg, #F8FAFE -54.87deg, #CBEFF4 35.62deg, #C7D6F6 144.38deg, #E6CBF4 208.12deg, #EDEDF4 376.73deg)',
        'conic-gradient(from 266.23deg at 55.1% 31.2%, #E6CBF4 89.88deg, #EDEDF4 136.73deg, #F8FAFE 166.87deg, #CBEFF4 275.62deg, #C7D6F6 384.38deg, #E6CBF4 448.12deg, #EDEDF4 496.73deg)']
    },
    duration: 30000,
    loop: true,

  })
}

class SectionAllDevices extends Component{
  componentDidMount() {
    // animateBG();
  }

  render(){
    return(
      <section className={classes.SectionAllDevices} id="SectionAllDevices">
        <div className={classes.BGCSS}>
          <img src={BG} alt=""/>
        </div>
        <div className="siteWidth">
          <h2 className={classes.title}>Available on all devices</h2>
          <div className={classes.imgWrap}>
            <img src={devices} alt=""/>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionAllDevices