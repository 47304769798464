import React, {Component} from "react";
import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import Header from "../../containers/Header/Header";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import SectionNft3 from "../../containers/SectionNft3/SectionNft3";
import SectionNft4 from "../../containers/SectionNft4/SectionNft4";
import SectionNft5 from "../../containers/SectionNft5/SectionNft5";
import SectionNft2 from "../../containers/SectionNft2/SectionNft2";
import Preloader from "../../components/Preloader/Preloader";




class Nft extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        EffectAnimation('SectionCounterForm');
    }

    render(){
        return(
            <>
                <Header
                    addClass="blackScroll"
                />
                    <SectionNft2/>
                    <SectionNft3/>
                    <SectionNft4/>
                    <SectionNft5/>
                    <SectionCounterForm
                        remove={1}
                    />

                <Footer/>
            </>
        )
    }
}

export default Nft