import classes from './SectionOurCommunity.module.scss'
import icon1 from "./icon1.svg";
import icon2 from "./icon2.svg";
import icon3 from "./icon3.svg";

const SectionOurCommunity = () =>{
  return (
    <section className={classes.SectionOurCommunity} id="SectionOutComunity">
      <div className="siteWidth">
        <h2 className={classes.title}>
          A revolutionary app for influencers and followers to earn tokens together.
        </h2>
        <div className={classes.innerWrapper}>
          <div className={classes.itemBlock}>
            <div className={classes.iconItem}>
              <img src={icon1} alt=""/>
            </div>
            <div className={classes.titleItem}>Invite your Contacts</div>
            <div className={classes.textItem}>
              Sizzle's networking programs reward members for helping grow the platform and the community.
            </div>
          </div>
          <div className={classes.itemBlock}>
            <div className={classes.iconItem}>
              <img src={icon2} alt=""/>
            </div>
            <div className={classes.titleItem}>Earn Tokens</div>
            <div className={classes.textItem}>
              Sizzle members are rewarded for enjoying free entertainment without annoying pop up ads, subscriptions fees or streaming interruptions
            </div>
          </div>
          <div className={classes.itemBlock}>
            <div className={classes.iconItem}>
              <img src={icon3} alt=""/>
            </div>
            <div className={classes.titleItem}>Create & Share Content
            </div>
            <div className={classes.textItem}>
              Sizzle rewards your creativity and your followers. The more your audience consumes and shares your content, the more SIZ tokens you both earn
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionOurCommunity