import logo from './logo.svg'
import logo2 from './logo2.svg'
import Button from '../../components/Button/Button'
import classes from './Header.module.scss';
import {NavLink} from 'react-router-dom'
import {$} from "dom7";

const links = [
  {to: 'https://ito-sizzle-media.netlify.app/', label: 'Initial Token Offering', primaryLink: true},
  {to: '/siz', label: 'SIZ Token', exact: false},
  {to: '/', label: 'Products', exact: true, parent:true, childTo1: '/nft', childLabel1: 'NFT Marketplace', childTo2: '/app', childLabel2: 'Sizzle Mobile App'},
  {to: '/team', label: 'Team', exact: false},
  {to: '/faq', label: 'FAQ', exact: false},
]




function renderLinks(){
  return links.map((link, index) => {
    return (
      <li key={index}>
        {link.primaryLink
          ? <a href={link.to} target="_blank">{link.label}</a>
          : <NavLink
            to={link.to}
            exact={link.exact}
            activeClassName='active'
          >
            {link.label}
          </NavLink>
        }
        {link.parent
          ? <ul className={classes.childrens}>
              <li>
                <NavLink
                  exact={link.exact}
                  to={link.childTo1}
                >
                  {link.childLabel1}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={link.exact}
                  to={link.childTo2}
                >
                  {link.childLabel2}
                </NavLink>
              </li>
            </ul>
          : null
        }
      </li>
    )
  })
}


function Header(props) {

  const cls = [
    classes.header,
    classes[props.addClass]
  ]

  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    let r = document.getElementById("navbar");
    if (document.body.scrollTop < 20 || document.documentElement.scrollTop < 20 ) {
      if(r.className.includes("blackScroll") === true) {
        r.style.marginTop = "0px"
      }
    }
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ) {
      if(r.className.includes("blackScroll") === true) {
        r.style.marginTop = "-70px"
      }

    }
    if (document.body.scrollTop > 1500 || document.documentElement.scrollTop > 2300){
      if(r.className.includes("blackScroll") === true) {
        r.style.marginTop = "0px"
      }
    }

  }

  return (
    <header  id="navbar" className={cls.join(' ')} >

      <div className="siteWidth">
        <div className={classes.innerWrapper}>
          <a href="/" className={classes.logo1}>
            <img src={logo} alt="" />
          </a>
          <a href="/" className={classes.logo2}>
            <img src={logo2} alt="" />
          </a>
          <nav className={classes.navigation}>
            <ul>
              {renderLinks()}
            </ul>
          </nav>
          <div className={classes.additionalBtns}>
            <div className="btnWrap">
              <Button
                text="Get invitation"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}


export default Header
