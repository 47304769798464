import React, {Component} from "react";
import classes from './SectionNft4.module.scss'
import ysvg from './y.svg'
import camerasvg from './photo.svg'
import svg1 from './1.svg'
import svg2 from './2.svg'
import svg3 from './3.svg'
import svg4 from './4.svg'
import svg5 from './5.svg'

class SectionNft4 extends Component{
    render(){
        return(
            <section className={classes.SectionNft4}>
                <div className={classes.wrap}>
                    <div className={classes.row}>
                        <h2>For Influencers</h2>
                    </div>
                    <div className={classes.row3}>
                        <div className={classes.boxItem}>
                                <img src={svg1}/>
                                <p>Create Amazing Videos with AI technologies</p>
                        </div>
                        <div className={classes.boxItem}>
                            <img src={svg2}/>
                            <p>Create Amazing Videos with AI technologies</p>
                        </div>
                        <div className={classes.boxItem}>
                            <img src={svg3}/>
                            <p>Create Amazing Videos with AI technologies</p>
                        </div>
                    </div>

                    <div className={classes.row3}>
                        <div className={classes.boxItem}>
                            <img src={svg4}/>
                            <p>Create Amazing Videos with AI technologies</p>
                        </div>
                        <div className={classes.boxItem}>
                            <img src={svg5}/>
                            <p>Create Amazing Videos with AI technologies</p>
                        </div>
                    </div>


                    {/*<div className={classes.row2}>*/}
                    {/*    <div className={classes.col}>*/}
                    {/*        <ul className={classes.list}>*/}
                    {/*            <li><img src={ysvg}/> Create Amazing Videos with AI technologies</li>*/}
                    {/*            <li><img src={ysvg}/> Create Amazing Videos with AI technologies</li>*/}
                    {/*            <li><img src={ysvg}/> Create Amazing Videos with AI technologies</li>*/}
                    {/*            <li><img src={ysvg}/> Create Amazing Videos with AI technologies</li>*/}
                    {/*            <li><img src={ysvg}/> Create Amazing Videos with AI technologies</li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.col}>*/}
                    {/*        <img className={classes.camera} src={camerasvg}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className={classes.row}>*/}
                    {/*    <div className={classes.boxbtn}>*/}
                    {/*        <button className={classes.button}>Get Private invitation</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>
        )
    }
}

export default SectionNft4