import {Component} from "react";
import classes from "./SectionIto5.module.scss";


class SectionIto5 extends Component {
    render() {
        return(
            <section className={classes.SectionIto5}>
                <div className={classes.wrapper}>
                    <div className={classes.h2}>
                        <h2>Sale Options</h2>
                        <p>Sale participants may choose to purchase Clover “CLV” tokens under the three different options below. Options may be combined. The allocations available will be as follows:</p>
                    </div>

                    <div className={classes.table}>
                            <table border="1">
                                <tr>
                                    <th className={classes.th}> </th>
                                    <th className={classes.th}>Community Round</th>
                                    <th className={classes.th}>Round 2</th>
                                    <th className={classes.th}>Round 3</th>
                                </tr>
                                <tr>
                                    <td className={classes.tdTitle}>Sale
                                        Dates</td>
                                    <td className={classes.td}>April 20 17:00 UTC -<br/>
                                    April 24 00:00 UTC</td>
                                    <td className={classes.td}>April 21 17:00 UTC -<br/>
                                    April 24 00:00 UTC</td>
                                    <td className={classes.td}>April 21 20:00 UTC -<br/>
                                    April 24 00:00 UTC</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>Lockup</td>
                                    <td className={classes.td}>12 months</td>
                                    <td className={classes.td}>6 months</td>
                                    <td className={classes.td}>40 days</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>Release</td>
                                    <td className={classes.td}>6-month monthly release<br/>
                                        period beginning on April<br/>
                                        24, 2022</td>
                                    <td className={classes.td}>6-month monthly release<br/>
                                        period beginning on October<br/>
                                        24, 2021</td>
                                    <td className={classes.td}>Freely trading 40 days<br/> from April 24, 2021</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>Purchase
                                        Limits</td>
                                    <td className={classes.td}>$100 min<br/>
                                        $1,000 max</td>
                                    <td className={classes.td}>$20, 000 min<br/>
                                        $200, 000 max*</td>
                                    <td className={classes.td}>$1 00 min<br/>
                                        $2, 000 max*</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>% of Total
                                        Supply</td>
                                    <td className={classes.td}>7.5%</td>
                                    <td className={classes.td}>4.0%</td>
                                    <td className={classes.td}>3.5%</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>Num of
                                        Tokens</td>
                                    <td className={classes.td}>75M</td>
                                    <td className={classes.td}>40M</td>
                                    <td className={classes.td}>35M</td>
                                </tr>

                                <tr>
                                    <td className={classes.tdTitle}>Price per
                                        Token</td>
                                    <td className={classes.td}>$0.20</td>
                                    <td className={classes.td}>$0.29</td>
                                    <td className={classes.td}>$0.35</td>
                                </tr>

                            </table>
                    </div>

                </div>
            </section>
        )
    }
}

export default SectionIto5