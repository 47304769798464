import React, {Component} from 'react';
import './preloader.css';
import EffectAnimation from "../EffectAnimation/EffectAnimation";

class Preloader extends Component{
    componentDidMount() {
        function offPreloader() {
            document.getElementById("preloader").style.display = "none";
        }

        setTimeout(offPreloader, 2500);
    }

    render() {
        return(
            <div className="preloader" id="preloader">
                <svg width="0" height="0">
                    <filter id="gooey-plasma-2">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 50 -16"
                                       result="goo"/>
                    </filter>
                </svg>
                <div className="plasma-2">
                    <ul className="gooey-container">
                        <li className="bubble"></li>
                        <li className="bubble"></li>
                        <li className="bubble"></li>
                        <li className="bubble"></li>
                        <li className="bubble"></li>
                        <li className="bubble"></li>
                    </ul>
                </div>
                <div className="preloaderText">LOADING...</div>
            </div>
            )
    }
}
export default Preloader