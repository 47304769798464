import React, {Component} from "react";
import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import Header from "../../containers/Header/Header";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import SectionMobileApp1 from "../../containers/SectionMobileApp1/SectionMobileApp1";
import SectionOurCommunity from "../../containers/SectionOurCommunity/SectionOurCommunity";
import SectionContentType from "../../containers/SectionContentType/SectionContentType";
import SectionFeatures from "../../containers/SectionFeatures/SectionFeatures";
import SectionMobileApp2 from "../../containers/SectionMobileApp2/SectionMobileApp2";
import classes from './MobileApp.module.scss'
import SectionMobileApp4 from "../../containers/SectionMobileApp4/SectionMobileApp4";
import SectionMobileApp5 from "../../containers/SectionMobileApp5/SectionMobileApp5";
import SectionSiz5 from "../../containers/SectionSiz5/SectionSiz5";
import SectionSiz4 from "../../containers/SectionSiz4/SectionSiz4";

class MobileApp extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        EffectAnimation('SectionCounterForm');
    }

    render(){
        return(
            <>
                <Header
                    addClass="black"
                />
                <main className={classes.main}>
                    <SectionMobileApp1/>
                    <SectionOurCommunity />
                    <SectionContentType />
                    <SectionFeatures />

                    <SectionMobileApp4/>
                    <SectionSiz4/>
                    <SectionCounterForm
                        remove={1}
                    />
                </main>
                <Footer/>
            </>
        )
    }
}

export default MobileApp