import React, {Component} from "react";

import Header from '../../containers/Header/Header'
import Footer from '../../containers/Footer/Footer'
import Section1 from '../../containers/Section1/Section1'
import SectionCounterForm from '../../containers/SectionCounterForm/SectionCounterForm'
// Animations
import EffectAnimation from '../../components/EffectAnimation/EffectAnimation'
import SectionFounders2 from "../../containers/SectionFounders2/SectionFounders2";
import SectionMarket from "../../containers/SectionMarket/SectionMarket"
import SectionProblem from "../../containers/SectionProblem/SectionProblem"
import SectionProduct from "../../containers/SectionProduct/SectionProduct"
import SectionCompareTable from "../../containers/SectionCompareTable/SectionCompareTable"
import SectionHowEarn from "../../containers/SectionHowEarn/SectionHowEarn"
import SectionAllDevices from "../../containers/SectionAllDevices/SectionAllDevices"
import SectionRoadmap from "../../containers/SectionRoadmap/SectionRoadmap"
import Preloader from "../../components/Preloader/Preloader";


let indicator1 = true;
let indicator2 = true;
let indicator3 = true;
let indicator4 = true;
let indicator5 = true;
let indicator6 = true;
let indicator7 = true;
let lastScrollTop = 0;



const ElementOffset = (el) => {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}


var keys = {37: 1, 38: 1, 39: 1, 40: 1};

const preventDefault = (e) => {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}


const disableScroll = () => {
  if (window.addEventListener) // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
  window.addEventListener("wheel", preventDefault, { passive:false });
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove  = preventDefault; // mobile
  document.onkeydown  = preventDefaultForScrollKeys;
}

const enableScroll = () => {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener('wheel', preventDefault, { passive:true });
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
  // indicator = true;
}

const whatDirection = () =>{
  var top = window.pageYOffset,
    direction = 'down';

  if(lastScrollTop > top){
    direction = 'up'
  }
  else if(lastScrollTop < top){
    direction = 'down'
  }
  lastScrollTop = top;

  return direction;
}


const scrollToSmoothly = (posi, timei) => {
  var currentPos = window.pageYOffset;
  var start = null;
  if(time == null) time = 500;
  let pos = +posi, time = +timei;

  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    var progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
}



const postScrollEvent = () =>{
  let clientWidth = window.innerWidth,
    clientHeight = window.innerHeight,
    lastScrollTop = 0,
    top = window.pageYOffset,
    down,
    middle = clientHeight / 2,
    bottomTrigger = (window.pageYOffset + clientHeight - 400),
    topTrigger = window.pageYOffset + 400,
    elements = ['#sectionMarket', '#sectionProblem', '#SectionHowEarn', '#SectionCompareTable', '#SectionAllDevices', '#SectionRoadmap'],
    element1 = document.querySelector('#sectionMarket'),
    divOffset1 = ElementOffset(element1),
    element2 = document.querySelector('#sectionProblem'),
    divOffset2 = ElementOffset(element2),
    element3 = document.querySelector('#SectionHowEarn'),
    divOffset3 = ElementOffset(element3),
    element4 = document.querySelector('#SectionCompareTable'),
    divOffset4 = ElementOffset(element4),
    element5 = document.querySelector('#SectionAllDevices'),
    divOffset5 = ElementOffset(element5),
    element6 = document.querySelector('#SectionRoadmap'),
    divOffset6 = ElementOffset(element6),
    element7 = document.querySelector('#SectionProduct'),
    divOffset7 = ElementOffset(element7);


  // console.log(bottomTrigger, divOffset.top, divOffset.top + element.offsetHeight, window.pageYOffset + 200);


  if(whatDirection() === 'down'){
    if(indicator1){
      if(topTrigger > divOffset1.top){
        indicator1 = false;
        scrollToSmoothly(element1.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator2){
      if(topTrigger > divOffset2.top){
        indicator2 = false;
        scrollToSmoothly(element2.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator3){
      if(topTrigger > divOffset3.top){
        indicator3 = false;
        scrollToSmoothly(element3.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator4){
      if(topTrigger > divOffset4.top){
        indicator4 = false;
        scrollToSmoothly(element4.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator5){
      if(topTrigger > divOffset5.top){
        indicator5 = false;
        scrollToSmoothly(element5.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator6){
      if(topTrigger > divOffset6.top){
        indicator6 = false;
        scrollToSmoothly(element6.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    if(indicator7){
      if(topTrigger > divOffset7.top){
        indicator7 = false;
        scrollToSmoothly(element7.offsetTop - 50, 400)
        disableScroll();
        setTimeout(function(){
          enableScroll();
        }, 700)

      }
    }
    else{

    }
  }
  else{
    if(bottomTrigger < divOffset1.top && indicator1 === false){
      indicator1 = true;
      console.log('trigger1');
    }
    if(bottomTrigger < divOffset2.top && indicator2 === false){
      indicator2 = true;
      console.log('trigger2');
    }
    if(bottomTrigger < divOffset3.top && indicator3 === false){
      indicator3 = true;
      console.log('trigger3');
    }
    if(bottomTrigger < divOffset4.top && indicator4 === false){
      indicator4 = true;
      console.log('trigger4');
    }
    if(bottomTrigger < divOffset5.top && indicator5 === false){
      indicator5 = true;
      console.log('trigger5');
    }
    if(bottomTrigger < divOffset6.top && indicator6 === false){
      indicator6 = true;
      console.log('trigger6');
    }
    if(bottomTrigger < divOffset7.top && indicator7 === false){
      indicator7 = true;
      console.log('trigger7');
    }
  }


  // let clientWidth = window.innerWidth,
  //   clientHeight = window.innerHeight,
  //   middle = clientHeight / 2,
  //   bottomTrigger = (window.pageYOffset + clientHeight - 300),
  //   element = document.querySelector('#sectionMarket'),
  //   divOffset = ElementOffset(element);
  //
  // if(bottomTrigger > divOffset.top){
  //   indicator = false;
  //   element.classList.add('scrollMe');
  // }
  //
  // if(element.classList.contains('scrollMe')){
  //   element.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start'
  //   })
  // }
}




class IndexPage extends Component {


  componentDidMount() {
    window.scrollTo(0, 0);
    EffectAnimation('section1');
    EffectAnimation('SectionCounterForm');
    // window.addEventListener('scroll', postScrollEvent);
    console.log("aaa")
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', postScrollEvent);
  }


  render(){
    return(
      <>
        <Preloader/>
        <Header />
        <main>
          <Section1 />
          <SectionFounders2 />
          <SectionMarket />
          <SectionProblem />
          <SectionProduct />
          <SectionHowEarn />
          <SectionCompareTable />
          <SectionAllDevices />
          <SectionRoadmap />
          <SectionCounterForm
            remove={1}
          />
        </main>
        <Footer/>
      </>
    )
  }
}

export default IndexPage;