import React, {Component} from "react";
import classes from './SectionMobileApp2.module.scss'
import camSvg from './cam.svg'
import coinsSvg from './coins.svg'
import socialsSvg from './socials.svg'

class SectionMobileApp2 extends Component{
    render() {
        return(
            <section className={classes.sectionMobileApp2}>
                <div className={classes.rowh}>
                    <h3>Revolutionary app that both <span>Influencers</span> and <span>Followers</span> can earn tokens together</h3>
                </div>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <img src={camSvg}/>
                        <p>Create Amazing Videos in seconds</p>
                    </div>
                    <div className={classes.col}>
                        <img src={socialsSvg} style={{paddingBottom: '18.5px'}}/>
                        <p>Create Amazing Videos in seconds</p>
                    </div>
                    <div className={classes.col}>
                        <img src={coinsSvg} style={{marginTop: '-30px'}}/>
                        <p>Create Amazing Videos in seconds</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionMobileApp2