import classes from "../SectionTeam/SectionTeam.module.scss";
import team1 from './team1.png'

function SectionTeam(){
    return(
        <section className={classes.SectionTeam} id="sectionTeam">
            <div className="siteWidth">
                <div className={classes.teamHead}>
                    <h1>Team</h1>
                </div>
                <div className={classes.row}>

                        <div className={classes.teamType}>Marketing department</div>

                        <div className={classes.teamPerson}>
                                <div className={classes.teamImg}><img src={team1}/></div>
                                <div className={classes.teamText}>
                                    <div className={classes.name}>Hamilton Souther</div>
                                    <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                    <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                    <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                                </div>
                        </div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                    <div className={classes.teamType}>Marketing department</div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                    <div className={classes.teamType}>Marketing department</div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>

                        <div className={classes.teamPerson}>
                            <div className={classes.teamImg}><img src={team1}/></div>
                            <div className={classes.teamText}>
                                <div className={classes.name}>Hamilton Souther</div>
                                <div className={classes.skills}>Co-founder, Chairman and CEO</div>
                                <div className={classes.description}>An entrepreneur and influencer that initiated the global movement to popularize the art of indigenous cultures.</div>
                                <div className={classes.linkedin}><a href="">Linkedin</a> </div>
                            </div>
                        </div>


                </div>
                <div className={classes.view}>
                    <button>View All</button>
                </div>

            </div>
        </section>
    )
}

export default SectionTeam