import classes from './SectionFounders.module.scss'
import founder1 from './founder1.png'
import founder2 from './founder2.png'
import founder3 from './founder3.png'
import founder4 from './founder4.png'
import founder5 from './founder5.png'
import founder6 from './founder6.png'
import founder7 from './founder7.png'
import founder8 from './founder8.png'
import founder9 from './founder9.png'
import founder10 from './founder10.png'
import founder11 from './founder11.png'
import founder12 from './founder12.png'
import founder13 from './founder13.png'
import founder14 from './founder14.png'


function SectionFounders(){
  return(
    <section className={classes.SectionCounterForm} id="SectionFounders">
      <h2 className={classes.title}>
        Sizzle founders have startups featured in:
      </h2>
      <div className={classes.inputWrapper}>
        <div className={classes.item}>
          <img src={founder1} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder2} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder3} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder4} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder5} alt=""/>
        </div>
        <span className={classes.breaker}>

        </span>
        <div className={classes.item}>
          <img src={founder6} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder7} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder8} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder9} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder10} alt=""/>
        </div>
        <span className={classes.breaker}>

        </span>
        <div className={classes.item}>
          <img src={founder11} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder12} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder13} alt=""/>
        </div>
        <div className={classes.item}>
          <img src={founder14} alt=""/>
        </div>
      </div>
    </section>
  )
}

export default SectionFounders