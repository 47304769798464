import React, {Component} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import IndexPage from './pages/IndexPage/IndexPage'
import InfluencersPage from './pages/InfluencersPage/InfluencersPage'
import FAQ from "./pages/FaqPage/FAQ";
import Team from "./pages/TeamPage/Team";
import Ito from "./pages/ItoPage/Ito";
import CryptoPage from './pages/CryptoPage/CryptoPage'
import EtPage from './pages/EtPage/EtPage'
import SPage from './pages/SPage/SPage'
import Nft from "./pages/Nft/Nftmarketplace";
import MobileApp from "./pages/MobileApp/MobileApp";
import Siz from "./pages/SizPage/Siz";
import Page from 'react-page-loading'




class App extends Component {
  render(){
    return(
      <Switch>

          {/*<Page loader={"bar"} color={"#603bc1"} size={5}>*/}
        <Route path="/" exact component={IndexPage} />
        <Route path="/ito" component={Ito} />
        <Route path="/Team" component={Team} />
        <Route path="/faq" component={FAQ} />
        <Route path="/influencers" exact component={InfluencersPage} />
        <Route path="/crypto" exact component={CryptoPage} />
        <Route path="/et" exact component={EtPage} />
        <Route path="/s" exact component={SPage} />
        <Route path="/nft" exact component={Nft} />
        <Route path="/app" exact component={MobileApp} />
        <Route path="/siz" exact component={Siz} />
        <Redirect to={{ pathname: "/" }} />
          {/*</Page>*/}
      </Switch>
    )
  }
}

export default App