import React, {Component} from "react";
import classes from './SectionNft5.module.scss'
import arrowSvg from './arrow.svg'
import cam from './creat.svg'
import coins from './paid.svg'
import steps from './steps.png'


class SectionNft5 extends Component {
    render() {
        return(
            <section className={classes.sectionNft5}>
                <div className={classes.wrap}>
                    <div className={classes.rowh2}>
                        <h2>For Agencies & Content Creators</h2>
                    </div>
                    <div className={classes.row}>
                    <div className={classes.col}>
                        <div className={classes.box}>
                            <img src={cam} width={126}/>
                            <h4>Create</h4>
                            <div className={classes.boxList}>
                                <div className={classes.imgList}>
                                    <img src={steps} style={{width: '36px'}}/>
                                </div>
                                <div className={classes.List}>
                                    <p className={classes.p1}>Create a video for a Brand</p>
                                    <p>Convert it to NFT and list on Sizzle Marketplace</p>
                                    <p>We will market it to the Brand at the highest price possible</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col2}>
                        <div className={classes.boxArrow}>
                            <img src={arrowSvg}/>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.box}>
                            <img src={coins} width={126}/>
                            <h4>Get paid</h4>
                            <div className={classes.boxList}>
                                <div className={classes.imgList}>
                                    <img src={steps} style={{width: '36px'}}/>
                                </div>
                                <div className={classes.List}>
                                    <p>You get paid for the views on the Sizzle App</p>
                                    <p>You get paid from the Advertisers</p>
                                    <p>You get paid for selling the video as NFT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}

export default SectionNft5