import React, {Component} from "react";
import classes from './SectionNft3.module.scss'
import xsvg from './x.svg'

class SectionNft3 extends Component {
    render(){
        return(
            <section className={classes.sectionNft}>
                <div className={classes.wrap}>
                    <div className={classes.row}>
                        <h2>Compare Sizzle to Others</h2>
                    </div>
                    <div className={classes.col}>
                        <h3>Others</h3>
                        <div className={classes.box}>
                            <div className={classes.left}>
                                <p>Cost of NFT Creation (Minting):</p>
                                <p>Need Metamusk Wallet:</p>
                                <p>Need account on Exchnages:</p>
                            </div>
                            <div className={classes.right}>
                                <p><img src={xsvg}/>$50-$160</p>
                                <p><img src={xsvg}/>Yes</p>
                                <p><img src={xsvg}/>Yes</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <h3>Sizzle Marketplace</h3>
                        <div className={classes.box2}>
                            <div className={classes.left}>
                                <p>Cost of NFT Creation (Minting):</p>
                                <p>Need Metamusk Wallet:</p>
                                <p>Need account on Exchnages:</p>
                            </div>
                            <div className={classes.right}>
                                <p>$2</p>
                                <p>No</p>
                                <p>No</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.row2}>
                        <p>The cost of the NFT creation on Sizzle Markeplace is <span>53X</span>   times lower
                            as opposed to other NFT maketplaces.</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionNft3