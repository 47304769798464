import React, {Component} from "react";
import EffectAnimation from "../../components/EffectAnimation/EffectAnimation";
import SectionCounterForm from "../../containers/SectionCounterForm/SectionCounterForm";
import Footer from "../../containers/Footer/Footer";
import SectionFaq from "../../containers/SectionFaq/SectionFaq";
import Header from "../../containers/Header/Header";

class FAQ extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        EffectAnimation('SectionCounterForm');
    }


    render(){
        return(
            <>
              <Header
                addClass="black"
              />
                <main>
                    <SectionFaq/>
                    <SectionCounterForm
                      remove={1}
                    />
                </main>
                <Footer/>
            </>
        )
    }
}

export default FAQ;