import React, {Component} from "react";
import classes from "./SectionSiz2.module.scss";
import './scene.css'

class SectionSiz2 extends Component {

    render() {
        return(
            <section className={classes.sectionSiz2}>
                <div className="siteWidth">
                    <div className={classes.innerWrapper}>
                        <div className={classes.text}>
                            <h2 className={classes.title}>
                                Sizzle Economy
                            </h2>
                            <p className={classes.someText}>
                                There are 3 participants in Sizzle's economy.<br/><br/>

                                Economy where the members of the community exchange
                                value amongst each other using the SIZ token.
                            </p>
                        </div>
                        <div className={classes.frame}>
                            <iframe src="/scene"/>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default SectionSiz2