import React, {Component} from "react";
import classes from "./SectionMobileApp1.module.scss";
import phoneImage from "../Section1/phoneImg.png";
import Slider from "react-slick";
import phoneImage2 from "../Section1/phoneImg2.png";
import phoneImage3 from "../Section1/phoneImg3.png";
import apple from './appstore.svg'
import android from './googlestore.svg'



function SectionMobileApp1 (props){
    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        autoplay: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        dotsClass:  `slick-dots ${classes.slick_dots}`
    };


    return(
        <section className={classes.section1 + ' illusionAnimation'} >
            <div className="siteWidth">
                <div className={classes.innerWrapper}>
                    <div className={classes.leftSide}>
                        <h1 className={classes.title}>
                            {props.title ? props.title : 'SIZZLE Media App'}
                        </h1>
                        <div className={classes.imgWrap}>
                            <img src={phoneImage} alt=""/>
                        </div>
                        <div className={classes.postTitle}>
                            Sizzle ITO is the only time you will be able to purchase SITO tokens. It's an exclusive opportunity for you to participate in the first blockchain-based video-sharing social networking hub.
                        </div>
                        <div className={classes.bottomSide}>
                            <a href="#" className={classes.links}> <img src={apple} alt="" className={classes.linksImg}/></a>

                            <a href="#" className={classes.links}> <img src={android} alt="" className={classes.linksImg}/></a>

                        </div>
                    </div>
                    <div className={classes.rightSide} id="section1">
                        <Slider {...sliderSettings} className={classes.SliderBlock}>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage2} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage3} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage2} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage3} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage2} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage3} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage2} alt=""/>
                            </div>
                            <div className={classes.imgWrap}>
                                <img src={phoneImage3} alt=""/>
                            </div>
                        </Slider>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default SectionMobileApp1