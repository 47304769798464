import socials from './socials.svg'
import classes from './SectionCompTwo.module.scss'

function SectionCompTwo(){
  return(
    <section className={classes.SectionCompTwo} id='SectionCompTwo'>
      <div className="siteWidth">
        <h2 className={classes.title}>
          Compare Sizzle to other Social Media
        </h2>
        <div className={classes.innerWrapper}>
          <div className={classes.leftSide}>
            <div className={classes.title}>
              Sizzle
            </div>
            <div className={classes.wrapper}>
              <ul>
                <li>
                  Stores the value in a utility token that can be earned without money
                </li>
                <li>
                  Community & content are assets that have value to reward members
                </li>
                <li>
                  Content is asset that belongs to the community
                </li>
                <li>
                  Preferences based matching algoritm for maximum engagement
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.word}>vs</div>
          <div className={classes.rightSide}>
            <div className={classes.title}>
              <img src={socials} alt=""/>
            </div>
            <div className={classes.wrapper}>
              <ul>
                <li>
                  Stores the value of the company in shares that you can only buy for money
                </li>
                <li>
                  Community & content are assets that rewards the holding company
                </li>
                <li>
                  Content is asset that belongs to the holding company
                </li>
                <li>
                  Both influencers & viewers cannot set content preferences
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default SectionCompTwo